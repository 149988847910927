import React, { useRef, useState } from "react";

const ImagePicker = ({ form, id }) => {
  const [myImagePreview, setMyImagePreview] = useState(null);

  const imageRef = useRef();

  const handleImageChange = async (e) => {
    if (!e?.dataTransfer?.files[0] && !e?.target?.files[0]) {
      return;
    }
    const files = e?.dataTransfer?.files || e?.target?.files;
    const convertedFiles = files[0];

    form?.setFieldValue(`${id}-name`, convertedFiles.name);

    if (!/(gif|jpe?g|png|jpg|svg|jpeg|pdf)$/i.test(convertedFiles.name)) {
      // dispatch(
      //   showErrorModal(
      //     "Invalid file type. Only PNG, jpg, GIF, JPEG, SVG, and PDF files are accepted."
      //   )
      // );
      return;
    }
    if ((e.target.files[0].size / 1024 / 1024).toFixed(4) > 5) {
      // dispatch(showErrorModal("File size is too big (max. 1.5MB)"));
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsDataURL(convertedFiles);
    fileReader.addEventListener("load", (result) => {
      setMyImagePreview(result.target.result);
    });
    form?.setFieldValue(id, convertedFiles);
  };

  return (
    <>
      <div
        onClick={() => {
          imageRef.current.click();
        }}
        className="flex relative items-center mt-5 mb-6"
      >
        <div className="w-[169.31px]  h-[169.31px]  bg-[#D9D9D9]  rounded-full">
          {myImagePreview || form?.values[id] ? (
            <img
              src={myImagePreview || form.values[id]}
              alt="camera"
              className="w-[169.31px] object-cover  h-[169.31px] absolute top-0 rounded-full"
            />
          ) : (
            <></>
          )}
        </div>
        <img
          src={require("../../Assets/Icons/camera.png")}
          alt="camera"
          className="w-[34px] z-10 h-[30px] ml-[-17px]"
        />
        <input
          id={"id"}
          type={"file"}
          accept=".png, .jpg, .jpeg, .svg"
          ref={imageRef}
          className={`hidden`}
          onChange={(e) => {
            handleImageChange(e);
            // onChange(e?.target?.value);
          }}
        />
      </div>
    </>
  );
};

export default ImagePicker;
