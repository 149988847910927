import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_WALLET } from "../../../config/apis";
import { Client } from "../../../config/client";
import { toast } from "react-toastify";

export const getWallet = createAsyncThunk("wallet/get", async () => {
  try {
    const { data } = await Client({
      method: "GET",
      path: GET_WALLET,
    });

    return data?.wallet;
  } catch (error) {
    // toast.error(error?.response?.data?.message || "Something went wrong");
    throw error;
  }
});
