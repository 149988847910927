import { createSlice } from "@reduxjs/toolkit";
import { getWallet } from "../actions/wallet/getWallet";
import { getTransactionDistribution } from "../actions/dasboard/getTransactionDistribution copy";
import { getTransactionsCountries } from "../actions/dasboard/getTransactionsCountries";
import { login } from "../actions/onboarding/login";
import { register } from "../actions/onboarding/register";
import { loginWithGoogle } from "../actions/onboarding/loginWithGoogle";
import { loginWithFacebook } from "../actions/onboarding/loginWithFacebook";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    error: null,
    pending: false,
    success: false,
    wallet: {},
    transactionsCountries: {},
    transactionDistribution: {},
  },

  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.transactionsCountries = {};
      state.wallet = {};
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.transactionsCountries = {};
      state.wallet = {};
    });
    builder.addCase(loginWithGoogle.fulfilled, (state, action) => {
      state.transactionsCountries = {};
      state.wallet = {};
    });
    builder.addCase(loginWithFacebook.fulfilled, (state, action) => {
      state.transactionsCountries = {};
      state.wallet = {};
    });
    builder.addCase(getWallet.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(getWallet.fulfilled, (state, action) => {
      state.wallet = action.payload;
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(getWallet.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
    builder.addCase(getTransactionDistribution.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(getTransactionDistribution.fulfilled, (state, action) => {
      state.transactionDistribution = action.payload;
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(getTransactionDistribution.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
    builder.addCase(getTransactionsCountries.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(getTransactionsCountries.fulfilled, (state, action) => {
      state.transactionsCountries = action.payload;
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(getTransactionsCountries.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
  },
});

export const {} = dashboardSlice.actions;
export default dashboardSlice.reducer;
