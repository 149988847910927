import React from "react";

const DropDownBackDrop = ({ handlehideDropDown }) => {
  return (
    <div
      onClick={() => {
        handlehideDropDown(false);
      }}
      className="w-screen h-screen z-[2] fixed top-0 left-0 bg-transparent"
    ></div>
  );
};

export default DropDownBackDrop;
