import React, { useRef } from "react";
import { RiCalendar2Line } from "react-icons/ri";
import { timeformatter } from "../../utils/dateformatter";

const DatePicker = ({
  onChange,
  title,
  form,
  id,
  value,
  mainClasses,
  inputClasses,
}) => {
  const dateRef = useRef();
  return (
    <>
      {title ? (
        <>
          <div className={`font-mons relative w-[367px] ${mainClasses}`}>
            <h4 className="text-[#707070] font-[400] text-[14px] mb-1 leading-[21.94px]">
              {title}
            </h4>

            <div
              onClick={() => {
                dateRef.current.showPicker();
              }}
              className={`flex w-full items-center disabled:cursor-not-allowed text-[13px] outline-none disabled:bg-black-10 placeholder:text-black-50 focus:border-[2px] focus:border-black-90 invalid:[&:not(:placeholder-shown):not(:focus)]:border-[2px] invalid:[&:not(:placeholder-shown):not(:focus)]:border-error-light transition-all ease-in-out duration-100 h-[44px] bg-[#FF] border-[0.5px] border-[#999999] rounded-[8px] px-2 md:px-4 ${inputClasses}`}
            >
              {value
                ? timeformatter(value, "formal")
                : timeformatter(new Date(), "formal")}
            </div>
            {form?.errors[id] ? (
              <h6 className="w-[367px]  text-[0.75rem] mt-[2px] mb-2 text-error text-left ">
                {form?.errors[id]}
              </h6>
            ) : (
              <></>
            )}
            <input
              id={"id"}
              type={"date"}
              ref={dateRef}
              // disabled={disabled ? disabled : false}
              className={`absolute opacity-0`}
              // defaultValue={defaultValue}
              onChange={(e) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div
            onClick={() => {
              dateRef.current.showPicker();
            }}
            className=" relative cursor-pointer flex gap-1 items-center"
          >
            <h4 className="font-[400] text-[14px] md:text-[16px] text-[#395AB5]">
              {value
                ? timeformatter(value, "formal")
                : timeformatter(new Date(), "formal")}
            </h4>
            <RiCalendar2Line size={"16px"} color={"#395AB5"} />
            <input
              id={"id"}
              type={"date"}
              ref={dateRef}
              // disabled={disabled ? disabled : false}
              className={`absolute opacity-0`}
              // defaultValue={defaultValue}
              onChange={(e) => {
                onChange(e?.target?.value);
              }}
            />
          </div>

          {form?.errors[id] ? (
            <h6 className="w-[367px]  text-[0.75rem] mt-[6px] mb-2 text-error text-left ">
              {form?.errors[id]}
            </h6>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default DatePicker;
