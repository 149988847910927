import React from "react";
import Button from "../Inputs/Button";

const ErrorMessage = ({ message, title, handleClose }) => {
  return (
    <div className="fixed   top-0 left-0 w-full h-screen flex justify-center items-center ">
      <div className="absolute bg-[rgba(0,0,0,0.58)] top-0 left-0 w-full h-full z-40"></div>
      <div className="font-mons w-[455px] bg-[#FDFDFD] rounded-[24px] h-[415] max-w-[75%] z-40 max-h-[70%] flex gap-2 pb-6 pt-9 flex-col justify-center items-center ">
        <div className="flex flex-col items-center">
          <img
            onClick={() => {}}
            src={require("../../Assets/Images/error1.png")}
            className="w-[179px] h-[178px] "
            alt="error message"
          />
          <h4 className="font-[600] text-[#000000]  mt-3 text-[20px] text-center max-w-[250px]">
            {title}
          </h4>
          <h4 className="font-[400] text-[#000000] mt-1 text-[16px] text-center max-w-[353px]">
            {message}
          </h4>
        </div>
        <Button
          title={"Done"}
          bg={"#192851"}
          onClick={handleClose}
          mainClasses={"h-[56px] hover:text-[red] hover:border-[#192851] "}
          textStyles={"font-[600] text-[18px] group-hover:text-[#192851]"}
        />
      </div>
    </div>
  );
};

export default ErrorMessage;
