import React, { useEffect, useState } from "react";
import TextInput from "../Inputs/TextInput";
import Button from "../Inputs/Button";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { useDispatch } from "react-redux";
import { loginSchema } from "../../utils/validationSchemas";
import { useFormik } from "formik";
import { formHandler } from "../../utils/formhandler";
import { login } from "../../store/actions/onboarding/login";
import { useCookies } from "react-cookie";
import { loginWithGoogle } from "../../store/actions/onboarding/loginWithGoogle";
import { loginWithFacebook } from "../../store/actions/onboarding/loginWithFacebook";
import { toast } from "react-toastify";

const now = new Date();
const next15Mins = new Date(now.setMinutes(now.getMinutes() + 15));
const next2Days = new Date(now.setDate(now.getDate() + 2));
const next30Days = new Date(now.setDate(now.getDate() + 30));

const LoginMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [remember, setRemember] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [facebookLoading, setFacebookLoading] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["auth"]);

  useEffect(() => {
    if (cookies.email) {
      setRemember(true);
    }
  }, [cookies.email]);

  const googleLogin = useGoogleLogin({
    scope: "email profile",
    onSuccess: (token, dem) => {
      setGoogleLoading(true);
      dispatch(
        loginWithGoogle({
          token: token.access_token,
          setSubmitting: setGoogleLoading,
          navigate,
        })
      );
    },
    onError: (error) => {
      setGoogleLoading(false);
      toast.error("Error. Please try again.");
    },
  });

  const form = useFormik({
    initialValues: {
      email: cookies?.email || "",
      password: cookies?.password || "",
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (remember) {
        setCookie("email", values.email, {
          // path: "/",
          expires: next30Days,
          secure: true,
        });
        setCookie("password", values.password, {
          // path: "/",
          expires: next30Days,
          secure: true,
        });
      } else {
        if (cookies.email) removeCookie("email");
        if (cookies.password) removeCookie("password");
      }
      const data = {
        ...values,
      };

      dispatch(login({ navigate, data, setSubmitting }));
    },
  });

  const responseFacebook = (response) => {};

  useEffect(() => {
    if (cookies?.email) {
      form.setFieldValue("email", cookies?.email);
    }
    if (cookies?.password) {
      form.setFieldValue("password", cookies?.password);
    }
  }, [cookies]);

  return (
    <>
      <div className="w-[90%] md:w-[577px] justify-between rounded-[28px] bg-[rgba(255,255,255,0.9)] flex-col flex py-4 3xl:py-8 px-6 md:h-[698px] max-h-[85%]   items-center">
        <div className="flex-col mt-0 3xl:mt-6 mb-5 flex items-center h-full w-full">
          <h3 className="text-[#000] mt-4 mb-1 font-[700] text-[24px] text-center">
            Login
          </h3>
          <h3 className="text-[#707070] font-[400] text-[16px] text-center">
            Please provide your details to continue
          </h3>
          <div className="flex-1 flex flex-col items-center overflow-y-auto">
            <TextInput
              title={"Email address"}
              {...formHandler("email", form)}
              type={"email"}
              mainClasses={"mb-[8px] mt-6 w-full md:w-[367px]"}
            />
            <TextInput
              title={"Password"}
              {...formHandler("password", form)}
              type={"password"}
              mainClasses={"w-full md:w-[367px]"}
            />

            <div className="mb-5 w-full md:w-[363px] mt-[4px] flex justify-between items-center ">
              <div
                onClick={() => {
                  setRemember((prev) => !prev);
                }}
                className="flex items-center gap-1 cursor-pointer"
              >
                <div
                  className={`${
                    remember ? "" : ""
                  } flex justify-center items-center rounded-[8px] border-[0.75px] h-[16px] w-[16px] border-[#1873B9] transition-all ease-in-out duration-300`}
                >
                  <div
                    className={`${
                      remember ? "bg-[#1873B9]" : "bg-transparent"
                    }  w-[8px] h-[8px] mr-[-0.3px] rounded-[4px] transition-all ease-in-out duration-300`}
                  ></div>
                </div>
                <h4 className="text-[#333] font-[500] text-[13px] ">
                  Remember me
                </h4>
              </div>
              <h4
                onClick={() => {}}
                className="text-[#333] font-[500] text-[13px] cursor-pointer"
              >
                Forgot Password?
              </h4>
            </div>
            <Button
              title={"Login"}
              bg={"#192851"}
              onClick={form.handleSubmit}
              loading={form.isSubmitting}
              disabled={(!form.dirty && !cookies?.email) || form.isSubmitting}
              mainClasses={" hover:text-[red] hover:border-[#192851] "}
              textStyles={"group-hover:text-[#192851]"}
            />

            <div className="w-full flex flex-col gap-3 3xl:gap-4 items-center">
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                onSuccess={(response) => {
                  setFacebookLoading(true);
                  dispatch(
                    loginWithFacebook({
                      token: response.accessToken,
                      setSubmitting: setFacebookLoading,
                      navigate,
                    })
                  );
                }}
                onFail={(error) => {
                  setFacebookLoading(false);

                  toast.error("Error. Please try again.");
                }}
                onProfileSuccess={(response) => {
                  // console.log("Get Profile Success!", response);
                }}
                render={({ onClick, logout }) => (
                  <Button
                    onClick={onClick}
                    leftIcon="facebook"
                    title={"Continue with Facebook"}
                    bg="#1977F3"
                    loading={facebookLoading}
                    width={"323px"}
                    // onClick={renderProps.onClick}
                    mainClasses={"w-full !my-0 md:w-[323px]"}
                  />
                )}
              />

              <Button
                onClick={() => {
                  googleLogin();
                }}
                loading={googleLoading}
                leftIcon="google"
                title={"Continue with Google"}
                bg={"#EA4235"}
                width={"323px"}
                mainClasses={" w-full !my-0 md:w-[323px]"}
              />
              <h3 className="text-[#707070] font-[400] mt-4 mb-3 text-[14px] text-center">
                Don’t have an account?{" "}
                <span
                  className="text-[#000000] cursor-pointer "
                  onClick={() => {
                    navigate("/sign-up");
                  }}
                >
                  Sign up
                </span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginMain;
