import { createAsyncThunk } from "@reduxjs/toolkit";
import { PAYMENTS } from "../../../config/apis";
import { Client } from "../../../config/client";
import { toast } from "react-toastify";

export const getPayments = createAsyncThunk("payments/get", async () => {
  try {
    const { data } = await Client({
      method: "GET",
      path: PAYMENTS,
    });
    return data?.data;
  } catch (error) {
    // toast.error(error?.response?.data?.message || "Something went wrong");
    throw error;
  }
});
