import { createSlice } from "@reduxjs/toolkit";
import { getTransactions } from "../actions/transactions/getTransactions";
import { getTransaction } from "../actions/transactions/getTransaction";
import { getTransferRates } from "../actions/transactions/getTransferRates";

export const transactionSlice = createSlice({
  name: "transactions",
  initialState: {
    error: null,
    pending: false,
    success: false,
    data: [],
    rates: [],
    dataDetails: {},
  },

  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTransactions.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(getTransactions.fulfilled, (state, action) => {
      state.data = action.payload;
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(getTransactions.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
    builder.addCase(getTransferRates.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(getTransferRates.fulfilled, (state, action) => {
      state.rates = action.payload;
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(getTransferRates.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
    builder.addCase(getTransaction.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(getTransaction.fulfilled, (state, action) => {
      state.dataDetails = action.payload;
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(getTransaction.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
  },
});

export const {} = transactionSlice.actions;
export default transactionSlice.reducer;
