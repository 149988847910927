import { createSlice } from "@reduxjs/toolkit";

import { register } from "../actions/onboarding/register";
import { login } from "../actions/onboarding/login";
import { loginWithGoogle } from "../actions/onboarding/loginWithGoogle";
import { loginWithFacebook } from "../actions/onboarding/loginWithFacebook";
import { createPaymentMethod } from "../actions/payments/createPaymentMethod";
import { getPaymentMethods } from "../actions/payments/getPaymentMethods";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    paymentMethods: [],
    error: null,
    pending: false,
    token: null,
    success: false,
  },

  reducers: {
    logOut: (state, action) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state, action) => {
      state.error = null;
    });
    builder.addCase(register.rejected, (state, action) => {
      state.error = null;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.error = null;
      state.user = action.payload;
    });
    builder.addCase(createPaymentMethod.pending, (state, action) => {
      state.error = null;
    });
    builder.addCase(createPaymentMethod.rejected, (state, action) => {
      state.error = null;
    });
    builder.addCase(createPaymentMethod.fulfilled, (state, action) => {
      state.error = null;
      // state.paymentMethods = [...state.paymentMethods, action.payload];
      state.token = action.payload;
    });
    builder.addCase(getPaymentMethods.pending, (state, action) => {
      state.error = null;
    });
    builder.addCase(getPaymentMethods.rejected, (state, action) => {
      state.error = null;
    });
    builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
      state.error = null;
      state.paymentMethods = action.payload;
    });
    builder.addCase(login.pending, (state, action) => {
      state.error = null;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.error = null;
      state.user = action.payload;
    });
    builder.addCase(loginWithGoogle.pending, (state, action) => {
      state.error = null;
    });
    builder.addCase(loginWithGoogle.rejected, (state, action) => {
      state.error = null;
    });
    builder.addCase(loginWithGoogle.fulfilled, (state, action) => {
      state.error = null;
      state.user = action.payload;
    });
    builder.addCase(loginWithFacebook.pending, (state, action) => {
      state.error = null;
    });
    builder.addCase(loginWithFacebook.rejected, (state, action) => {
      state.error = null;
    });
    builder.addCase(loginWithFacebook.fulfilled, (state, action) => {
      state.error = null;
      state.user = action.payload;
    });
  },
});

export const {
  completeOnboarding,
  setCleanerslocation,
  removeClean,
  updateClean,
  setAmendRequest,
  logOut,
  resetCleanCanceled,
  clearContactMe,
  showContactMeAction,
  resetCleanRequest,
  clearCleanRequest,
  clearPendingRatings,
  setRequestOppParty,
} = userSlice.actions;
export default userSlice.reducer;
