import { createAsyncThunk } from "@reduxjs/toolkit";
import { RECURRING } from "../../../config/apis";
import { Client } from "../../../config/client";
import { toast } from "react-toastify";

export const getRecurring = createAsyncThunk("recurring/get", async (id) => {
  try {
    const {
      data: { user },
    } = await Client({
      method: "GET",
      path: `${RECURRING}${id}`,
    });
    toast.success("--");
    return user;
  } catch (error) {
    // toast.error(error?.response?.data?.message || "Something went wrong");
    throw error;
  }
});
