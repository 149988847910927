import React, { useEffect, useState } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import PaymentMethod from "../Components/Main/PaymentMethod";
import Recipient from "../Components/Main/Recipient ";
import TransactionSummary from "../Components/Main/TransactionSummary";
import Button from "../Components/Inputs/Button";
import SuccessMessage from "../Components/Utils/SuccessMessage";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorMessage from "../Components/Utils/ErrorMessage";
import { transfer } from "../store/actions/transactions/transfer";
import { useDispatch, useSelector } from "react-redux";
import { currencySymbolMap } from "../utils/data";
import SideBar from "../Components/Bars/SideBar";

const Payment = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [savedBeneficiary, saveBeneficiary] = useState(
    state?.beneficiary?.label
  );
  const { token: cardToken } = useSelector((state) => state?.user);
  const handleSubmit = (values) => {
    const { beneficiary, recieverAmount, currency, ...rest } = values;

    const data = {
      ...rest,
      bankName: values?.bankName?.label,
      swiftCode: values?.bankName?.code || beneficiary?.swiftCode,
      savedBeneficiary,
      token: cardToken,
    };
    setSubmitting(true);
    dispatch(
      transfer({
        currency,
        cb: () => {
          setSubmitting(false);
          setShowSuccess(true);
          // setShowSuccess(true);
        },
        payload: data,
        failed: () => {
          setSubmitting(false);
        },
      })
    );
  };

  useEffect(() => {
    if (!state) {
      navigate(-1);
    }
  }, []);

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Transfer"}
          description={""}
          name={"Imperial Finance"}
          type={"article"}
        />
        <Header />
        <div className="lg:hidden">
          <SideBar />
        </div>
        <PaymentMethod />
        <Recipient
          data={state}
          savedBeneficiary={savedBeneficiary}
          saveBeneficiary={saveBeneficiary}
        />
        <TransactionSummary data={state} />
        <div className="mx-auto  flex flex-col gap-1 pb-10 items-center">
          <h4 className="text-[14px] md:text-[18px] my-2 text-[#707070] text-center leading-5 font-[400]">
            Estimated arrival: usually in seconds
          </h4>
          <Button
            bg={"#192851"}
            title={"Send"}
            textStyles="font-[600] text-[18px]"
            mainClasses="h-[56px]"
            // onClick={form.handleSubmit}
            onClick={() => {
              handleSubmit({ ...state, savedBeneficiary });

              // setShowError(true);
            }}
            loading={isSubmitting}
            // loading={form.isSubmitting}
            // disabled={!form.dirty || !form.isValid || form.isSubmitting}
          />
        </div>
        <Footer />
      </div>
      {showSuccess ? (
        <SuccessMessage
          handleClose={() => {
            setShowSuccess(false);
            navigate("/");
          }}
          message={`${currencySymbolMap[state?.currency]}${
            state?.amount
          } sent successfully`}
        />
      ) : (
        <></>
      )}
      {showError ? (
        <ErrorMessage
          handleClose={() => {
            setShowSuccess(false);
            navigate("/sign-in");
          }}
          title={"Error"}
          message={
            "Oops! It seems like there was a hiccup in processing your transaction."
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Payment;
