import React, { useEffect, useState } from "react";

import Button from "../Inputs/Button";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SuccessMessage from "../Utils/SuccessMessage";
import { verifyEmail } from "../../store/actions/onboarding/verifyEmail";
import { resendToken } from "../../store/actions/onboarding/resendToken";

const OTPMain = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isSubmitting, setSubmitting] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);
  const email = location?.state?.email;

  const handleVerify = (code) => {
    setSubmitting(true);
    dispatch(
      verifyEmail({
        data: { code },
        setSubmitting,
        cb: () => {
          setSubmitting(false);
          setShowSuccess(true);
        },
      })
    );
  };

  const handleResendOTP = (email) => {
    const data = { email };
    setSubmitting(true);
    dispatch(
      resendToken({
        data,
        setSubmitting,
        cb: () => {
          setSubmitting(false);
        },
      })
    );
  };

  useEffect(() => {
    if (!email) {
      navigate(-1);
    }
  }, [email, navigate]);
  return (
    <>
      <div className="w-[90%] md:w-[577px] justify-center rounded-[28px] bg-[rgba(255,255,255,0.9)] flex-col flex py-4 3xl:py-8 px-6 md:h-[698px] max-h-[85%]   items-center">
        <div className="flex-col mt-0 3xl:mt-6 mb-5 flex items-center w-full">
          <img
            className="w-[121px] h-[121px] object-contain cursor-pointer mb-6"
            src={require("../../Assets/Images/password-icon.png")}
            alt="password"
          />
          <h3 className="text-[#000] mt-4 mb-1 font-[700] text-[24px] text-center">
            Verify Email Address
          </h3>

          <div>
            <h3 className="text-[rgba(112,112,112,1)] mt-4 font-[400] text-[14px] text-center">
              Enter code the code that we have sent to
            </h3>
            <h3 className="text-[rgba(112,112,112,1)] mt-1 font-[500] text-[15px] text-center">
              {email}
            </h3>
          </div>
          <OtpInput
            value={otp}
            onChange={setOtp}
            inputType="otp"
            numInputs={4}
            renderInput={(props) => <input {...props} />}
            placeholder="----"
            containerStyle={{
              display: "flex",
              justifyContent: "center",
              marginTop: "34px",
              marginBottom: "18px",
            }}
            shouldAutoFocus={true}
            inputStyle={{
              outlineColor: "#475C94",

              width: "50px",
              height: "50px",
              // border: "1.5px solid #475C94",
              border: "1.5px solid transparent",
              borderRadius: "14px",
              fontWeight: "bold",
              color: "#333333",
              fontSize: "20px",
              marginLeft: "6px",
              marginRight: "6px",
            }}
          />

          <h4
            onClick={() => (isSubmitting ? null : handleResendOTP(email))}
            className="cursor-pointer text-[#192851] font-[600] text-[16px] text-center"
          >
            Resend Code
          </h4>

          <Button
            bg={"#192851"}
            title={"Next"}
            disabled={isSubmitting || otp?.length < 4}
            loading={isSubmitting}
            onClick={() => {
              handleVerify(otp);
            }}
            mainClasses={"mt-10"}
          />
        </div>
      </div>
      {showSuccess ? (
        <SuccessMessage
          handleClose={() => {
            setShowSuccess(false);
            navigate("/sign-in");
          }}
          message={"Verified successfully"}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default OTPMain;
