import { createAsyncThunk } from "@reduxjs/toolkit";
import { VERIFY_EMAIL } from "../../../config/apis";
import { Client } from "../../../config/client";
import { processSuccessMessage } from "../../../utils/processSuccessMessage";
import { proccessErrorMessage } from "../../../utils/proccessErrorMessage";
import { toast } from "react-toastify";

export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (payload) => {
    const { data, setSubmitting, cb } = payload;
    try {
      const { data: _data } = await Client({
        method: "POST",
        path: VERIFY_EMAIL,
        data: data,
      });
      processSuccessMessage(_data?.message);

      cb();
      // return _data.user;
    } catch (error) {
      toast.error("Error verifying your email");
      setSubmitting(false);
      proccessErrorMessage(error);
      throw error;
    }
  }
);
