import React, { useEffect, useState } from "react";
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from "react-icons/io";

const RadioButton = ({
  id,
  onChange,
  value,
  disabled,
  form,
  defaultValue,
  childrenContent,
}) => {
  const handleCategorySelects = (category) => {
    onChange(category.name);
  };

  return (
    <div className={`flex  flex-col w-full group `}>
      <div className={``}>
        <div className="flex flex-wrap justify-start gap-y-3 gap-x-7 items-center my-2  ">
          {[...childrenContent].map((category, index) => (
            <div
              onClick={() =>
                disabled ? null : handleCategorySelects(category)
              }
              key={index}
              className={`cursor-pointer items-center flex gap-1
                  ${
                    category.name.toLowerCase().includes(value?.toLowerCase())
                      ? ""
                      : ""
                  } `}
            >
              {category.name.toLowerCase().includes(value?.toLowerCase()) ? (
                <IoMdRadioButtonOn size={"20px"} color={"#1DC9A0"} />
              ) : (
                <IoMdRadioButtonOff size={"20px"} color={"#D1D1D6"} />
              )}
              <h4 className="text-[16px] font-[400] text-[#707070] ">
                {category.name}
              </h4>
            </div>
          ))}
        </div>
      </div>
      {form?.errors[id] ? (
        <h6 className=" text-[0.75rem] mt-[0px] mb-2 text-error text-left w-full">
          {form?.errors[id]}
        </h6>
      ) : (
        <></>
      )}
    </div>
  );
};

export default RadioButton;
