// import Toast from "react-native-toast-message";

export const showToast = ({ type, title, message, top, bottom }) => {
  // Toast.show({
  //   type: type === 1 ? "info" : type === 0 ? "error" : "success",
  //   text1: "Notification Message" || title,
  //   text2: message,
  //   position: bottom ? "bottom" : "top",
  //   bottomOffset: bottom,
  //   topOffset: top || 60,
  //   onPress: () => {
  //     Toast.hide();
  //   },
  // });
};
