import React, { useState } from "react";
import { IoChevronDownSharp, IoChevronForwardSharp } from "react-icons/io5";

const FAQ = () => {
  const [openedFAQ, setOpenedFAQ] = useState(null);
  const faqs = [
    {
      name: "How can i start using the transfer feature?",
      details: "",
    },
    {
      name: "Do i incur charges when i transfer?",
      details: "",
    },
    {
      name: "How long does it take for funds to be delivered?",
      details: "",
    },
    {
      name: "How do i refer friends?",
      details: "",
    },
  ];

  return (
    <div className="w-full bg-[url('./Assets/Images/faq-image.png')] bg-no-repeat h-full   font-mons ">
      <div className="w-full h-full pt-8 pb-10 md:pt-12 md:pb-16  bg-[rgba(255,255,255,0.85)]">
        <div className="container mx-auto px-5 ">
          <div className="xl:max-w-[85%] mx-auto  mb-8">
            <h1 className="text-[#000] text-[37px] font-[700]">FAQ</h1>
            <h3 className="text-[#000] text-[24px] font-[600]">
              Frequently asked questions
            </h3>
          </div>

          <div className="flex flex-col md:flex-row xl:max-w-[85%] justify-between mx-auto ">
            <div className="flex flex-col flex-1 ">
              {faqs?.map((review, index) => {
                return (
                  <div
                    onClick={() => {
                      setOpenedFAQ(index);
                    }}
                    key={index}
                    className="max-w-[676px] cursor-pointer px-2 py-5  border-b border-b-[#D5D0D0]"
                  >
                    <div className="  flex justify-between items-center">
                      <h6 className="text-[#000] mt-1 text-[20px] font-[400]">
                        {review.name}
                      </h6>
                      {openedFAQ === index ? (
                        <IoChevronDownSharp size={"25px"} color={"#9A9A9A"} />
                      ) : (
                        <IoChevronForwardSharp
                          size={"25px"}
                          color={"#9A9A9A"}
                        />
                      )}
                    </div>
                    {openedFAQ === index ? (
                      <h6 className="text-[#000] opacity-[.85] mt-1 text-[14px] font-[400]">
                        {review.details}
                      </h6>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="hidden sm:block">
              <img
                className="w-[250p] lg:w-[444.41px] h-[170px] lg:h-[276px] -rotate-[9deg] object-contain mx-auto md:mx-0 cursor-pointer mb-6"
                src={require("../../Assets/Images/faq-image.png")}
                alt="faqs"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
