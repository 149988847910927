import { createAsyncThunk } from "@reduxjs/toolkit";
import { BENEFICIARIES } from "../../../config/apis";
import { Client } from "../../../config/client";
import { toast } from "react-toastify";

export const createBeneficiary = createAsyncThunk(
  "beneficiaries/post",
  async ({ payload, cb, failed }) => {
    try {
      const {
        data: { user },
      } = await Client({
        method: "POST",
        path: BENEFICIARIES,
        data: payload,
      });
      cb();
      return user;
    } catch (error) {
      failed();
      toast.error(error?.response?.data?.message || "Something went wrong");
      throw error;
    }
  }
);
