import React, { useEffect } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import SignupMain from "../Components/Auth/SignupMain";

const Signup = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="font-mons">
      <SEO
        title={"Signup on Imperial Finance"}
        description={""}
        name={"Imperial Finance"}
        type={"article"}
      />
      <Header />
      <div className="font-mons bg-[url('./Assets/Images/auth-background.png')] bg-cover h-screen flex justify-center items-center w-full pt-14">
        <SignupMain />
      </div>
      <Footer />
    </div>
  );
};

export default Signup;
