import React from "react";
import { BsClock } from "react-icons/bs";

const Post = ({ type, mode, handleClick }) => {
  return (
    <div
      onClick={handleClick}
      className={`w-full cursor-pointer bg-[#FFF] ${
        type == "wide" ? "mb-3" : "mb-7"
      } flex flex-col  ${mode === "viewAll" ? "h-[919px]" : "h-[392px]"}`}
    >
      <img
        src={require("../../Assets/Images/blog1.png")}
        alt="currency"
        className="w-full h-[236.74px] object-cover"
      />
      <div className="px-7 pt-2 pb-4 flex-col flex justify-between flex-1 mx-auto">
        <h4 className="text-[18px] lg:text-[20px] font-[600] text-[#000]">
          Stock Market Surges to New Heights as Tech Giants Lead Rally Amidst
          Economic Recovery Optimism...
        </h4>
        <div
          className={`flex gap-1 items-center ${
            type === "wide" && !mode ? "justify-end" : ""
          }`}
        >
          <BsClock size={"14px"} color={"#707070"} />
          <h4 className="text-[12px] sm:text-[14px] font-[500] text-[#707070]">
            14 hours ago
          </h4>
        </div>
        {mode === "viewAll" ? (
          <>
            <div className="border-t w-full border-t-[#9B9A9A] "></div>
            <div className="flex overflow-y-scroll h-[487px] flex-col gap-4">
              <h4 className="font-[400] mt-1 text-[12px] sm:text-[14px] text-[#000000]">
                In a remarkable display of resilience and optimism, the stock
                market soared to new heights today, buoyed by a strong rally led
                by tech giants amidst signs of economic recovery. Investors
                breathed a collective sigh of relief as major indices surged,
                signaling a renewed sense of confidence in the post-pandemic
                economic landscape.
              </h4>
              <h4 className="font-[400] mt-1 text-[12px] sm:text-[14px] text-[#000000]">
                The tech sector, in particular, played a pivotal role in driving
                the market rally, with industry titans such as Apple, Amazon,
                Microsoft, and Alphabet (Google's parent company) posting
                impressive gains. The surge in tech stocks underscored
                investors' confidence in the sector's ability to thrive in the
                evolving digital economy, as remote work, e-commerce, and cloud
                computing continue to dominate.
              </h4>
              <h4 className="font-[400] mt-1 text-[12px] sm:text-[14px] text-[#000000]">
                Analysts pointed to several factors contributing to the market's
                bullish sentiment. The gradual easing of pandemic-related
                restrictions, coupled with successful vaccination campaigns, has
                fueled hopes of a robust economic.
              </h4>
              <h4 className="font-[400] mt-1 text-[12px] sm:text-[14px] text-[#000000]">
                In a remarkable display of resilience and optimism, the stock
                market soared to new heights today, buoyed by a strong rally led
                by tech giants amidst signs of economic recovery. Investors
                breathed a collective sigh of relief as major indices surged,
                signaling a renewed sense of confidence in the post-pandemic
                economic landscape.
              </h4>
              <h4 className="font-[400] mt-1 text-[12px] sm:text-[14px] text-[#000000]">
                Analysts pointed to several factors contributing to the market's
                bullish sentiment. The gradual easing of pandemic-related
                restrictions, coupled with successful vaccination campaigns, has
                fueled hopes of a robust economic.
              </h4>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Post;
