import { createAsyncThunk } from "@reduxjs/toolkit";
import { PROFILE } from "../../../config/apis";
import { Client } from "../../../config/client";
import { toast } from "react-toastify";

export const completeProfile = createAsyncThunk(
  "profile/post",
  async ({ payload, cb, failed }) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: PROFILE,
        data: payload,
        contentType: "multipart/form-data",
      });
      toast.success("---");
      cb();

      return data?.data;
    } catch (error) {
      failed();
      toast.error(error?.response?.data?.message || "Something went wrong");
      throw error;
    }
  }
);
