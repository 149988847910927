import { createAsyncThunk } from "@reduxjs/toolkit";
import { RECURRING } from "../../../config/apis";
import { Client } from "../../../config/client";
import { toast } from "react-toastify";

export const createRecurring = createAsyncThunk(
  "recurring/post",
  async ({ payload, cb, failed }) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: RECURRING,
        data: payload,
      });
      cb();

      // return user;
    } catch (error) {
      failed();
      toast.error(error?.response?.data?.message || "Something went wrong");
      throw error;
    }
  }
);
