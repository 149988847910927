import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import "./fonts/ttinterfaces/TTInterfaces-Light.ttf";
import "./fonts/ttinterfaces/TTInterfaces-Bold.ttf";
import "./fonts/ttinterfaces/TTInterfaces-Regular.ttf";
import "./fonts/ttinterfaces/TTInterfaces-Medium.ttf";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store/store";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <CookiesProvider>
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </React.StrictMode>
    </CookiesProvider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
