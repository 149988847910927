import React from "react";
import {
  currencyCountryMap,
  mainCurrency,
  mainCurrency2,
} from "../../utils/data";

const Rate = ({ rate }) => {
  const { currency, quotes } = rate;

  return (
    <div
      // onClick={() => {
      //   navigate("/settings/editprofile");
      // }}
      className="bg-gradient-to-b from-[#FFF] to-[rgba(153,153,153,0.6)] flex items-center h-[61px] w-full mt-4 sm:mt-6 mx-auto sm:w-[530px] rounded-[10px] border-[0.1px] border-[rgba(0,0,0,0.5)] px-2 cursor-pointer border-b-[0.7px] border-b-[#707070]  justify-between"
    >
      <div className="flex items-center gap-6  sm:gap-8">
        <div className="flex justify-center items-center w-[38px] h-[38px]">
          {currency === "GBP" ? (
            <img
              src={require("../../Assets/Icons/currency-gbp.png")}
              alt="currency"
              className="w-[34px] h-[34px]"
            />
          ) : currency === "EUR" ? (
            <img
              src={require("../../Assets/Icons/currency-gbp.png")}
              alt="currency"
              className="w-[34px] h-[34px] rounded-full"
            />
          ) : currency === "USD" ? (
            <img
              src={require("../../Assets/Icons/currency-gbp.png")}
              alt="currency"
              className="w-[34px] h-[34px] rounded-full"
            />
          ) : currency === "NGN" ? (
            <img
              src={require("../../Assets/Icons/currency-ng.png")}
              alt="currency"
              className="w-[34px] h-[34px] rounded-full"
            />
          ) : (
            <></>
          )}
        </div>
        <div>
          <h4 className="text-[14px] sm:text-[16px] font-[600] text-[#000]">
            {currencyCountryMap[currency]}
          </h4>
          <h4 className="text-[12px] sm:text-[14px] font-[400] text-[#000]">
            {currency}
          </h4>
        </div>
      </div>
      <h4 className="text-[12px] sm:text-[14px] font-[400] text-[#000]">
        {`1${mainCurrency} = ${
          quotes[`${currency}${mainCurrency}`] || "--"
        }${currency}`}
      </h4>
    </div>
  );
};

export default Rate;
