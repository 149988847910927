import React from "react";
import Button from "../Inputs/Button";
import { BsTrash3 } from "react-icons/bs";

const ConfirmDelete = ({
  message,
  handleClose,
  handleConfirm,
  pendingConfirm,
}) => {
  return (
    <div className="fixed   top-0 left-0 w-full h-screen flex justify-center items-center ">
      <div className="absolute bg-[rgba(0,0,0,0.58)] top-0 left-0 w-full h-full z-40"></div>
      <div className="font-mons w-[455px] bg-[#FDFDFD] py-4 rounded-[24px] h-[213px] max-w-[75%] z-40 flex gap-5 flex-col justify-center items-center ">
        <div className="flex flex-col items-center">
          <BsTrash3 color="#E00202" size={"45px"} />
          <h4 className="font-[500] text-[#000000]  mt-4 text-[14px] text-center max-w-[196.92px]">
            {message}
          </h4>
        </div>
        <div className="flex items-center gap-3">
          <Button
            title={"No, cancel"}
            bg={"#192851"}
            onClick={handleClose}
            mainClasses={
              "!h-[45px] !mt-0 !mb-0 !w-[136.67px] hover:text-[red] hover:border-[#192851] "
            }
            textStyles={"!font-[500] !text-[16px] group-hover:text-[#192851]"}
          />
          <Button
            title={"Yes, remove"}
            bg={"#100202"}
            loading={pendingConfirm}
            onClick={handleConfirm}
            mainClasses={
              "!h-[45px] !mt-0 !mb-0 !w-[136.67px] !bg-[#E00202] hover:text-[red] hover:border-[#192851] "
            }
            textStyles={"!font-[500] !text-[16px] group-hover:text-[#192851]"}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmDelete;
