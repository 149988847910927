import { createAsyncThunk } from "@reduxjs/toolkit";
import { FUND_WALLET } from "../../../config/apis";
import { Client } from "../../../config/client";
import { toast } from "react-toastify";

export const fundWallet = createAsyncThunk(
  "wallet/post",
  async ({ payload, cb, currency, failed }) => {
    try {
      const {
        data: { user },
      } = await Client({
        method: "POST",
        path: `${FUND_WALLET}${currency}`,
        data: payload,
      });
      cb();
      return user;
    } catch (error) {
      failed();
      toast.error(error?.response?.data?.message || "Something went wrong");
      throw error;
    }
  }
);
