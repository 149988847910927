import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_TRANSACTION_COUNTRIES } from "../../../config/apis";
import { Client } from "../../../config/client";
import { toast } from "react-toastify";

export const getTransactionsCountries = createAsyncThunk(
  "TransactionMetrics/get",
  async () => {
    try {
      const { data } = await Client({
        method: "GET",
        path: GET_TRANSACTION_COUNTRIES,
      });

      return data?.data;
    } catch (error) {
      // toast.error(error?.response?.data?.message || "Something went wrong");
      throw error;
    }
  }
);
