import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "../../utils/formatAmount";
import { currencySymbolMap } from "../../utils/data";
import { useSelector } from "react-redux";

const TransactionSummary = ({ data }) => {
  const {
    accountNumber,
    amount,
    recieverAmount,
    bankName,
    beneficiary,
    currency,
    recipient,
    recipientCurrency,
    reference,
    swiftCode,
  } = data;
  const navigate = useNavigate();
  const [selected, setSelected] = useState(false);

  const { rates } = useSelector((state) => state.transactions);
  const rate = rates?.find((rate) => rate?.currency === currency)?.quotes[
    `${currency}${recipientCurrency}`
  ];

  return (
    <div className="w-full h-full my-4    font-mons">
      <div className="container w-full bg-[rgba(237,242,255,.5)] max-w-[90%] px-9 py-6 flex flex-col justify-center items-center mx-auto lg:min-h-[261px] rounded-[22px] ">
        <div className="w-full max-w-[627px] flex flex-col ">
          <div className="flex  w-full mb-2 justify-between items-center ">
            <h4 className="font-[600] text-[18px] md:text-[20px] text-[#000]">
              Transaction summary
            </h4>
            <h4
              onClick={() => {
                navigate("/transfer", { state: data });
              }}
              className="font-[600] text-[18px] md:text-[20px] text-[#395AB5] cursor-pointer "
            >
              Edit
            </h4>
          </div>
          <div className="flex  w-full my-3 justify-between items-center ">
            <h5 className="font-[400]  text-[14px] md:text-[18px] text-[#000] ">
              You paid
            </h5>
            <h5 className="font-[400] text-[14px] md:text-[18px] text-[#000] ">
              {currencySymbolMap[currency]}
              {formatAmount(amount)}
            </h5>
          </div>
          <div className="flex  w-full my-3 justify-between items-center ">
            <h5 className="font-[400]  text-[14px] md:text-[18px] text-[#000] ">
              Transfer Charge
            </h5>
            <h5 className="font-[400]  text-[14px] md:text-[18px] text-[#000] ">
              {currencySymbolMap[currency]}
              {formatAmount(0)}
            </h5>
          </div>
          <div className="flex  w-full my-3 justify-between items-center ">
            <h5 className="font-[400]  text-[14px] md:text-[18px] text-[#000] ">
              Total amount sent
            </h5>
            <h5 className="font-[400]  text-[14px] md:text-[18px] text-[#000] ">
              {currencySymbolMap[currency]}
              {formatAmount(amount)}
            </h5>
          </div>
          <div className="flex  w-full my-3 justify-between items-center ">
            <h5 className="font-[600]  text-[14px] md:text-[18px] text-[#000] ">
              Total received by recipient
            </h5>
            <h5 className="font-[600]  text-[14px] md:text-[18px] text-[#000] ">
              {recipientCurrency} {formatAmount(recieverAmount)}
            </h5>
          </div>
          <h4 className="text-[14px] md:text-[18px] mt-2 text-[#707070] text-center leading-5 font-[400]">
            Exchange rate: 1 {currency} = {rate} {recipientCurrency}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default TransactionSummary;
