import React, { useEffect, useState } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import SideBar from "../Components/Bars/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Loader from "../Components/Utils/Loader";
import { formatAmount } from "../utils/formatAmount";
import { timeformatter } from "../utils/dateformatter";
import { getPayments } from "../store/actions/payments/getPayments";
import { currencySymbolMap } from "../utils/data";

const TrackPayments = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const form = useFormik({
    initialValues: {
      fullName: "",
      bankName: "",
    },
    // validationSchema: signupSchema,
    onSubmit: (values, { setSubmitting }) => {
      const data = {
        ...values,
      };

      // dispatch(register({ navigate, data, setSubmitting }));
    },
  });

  const { pending, data } = useSelector((state) => state?.tracking);
  useEffect(() => {
    dispatch(getPayments());
  }, []);

  // "id": 24,
  // "transaction_ref": "IMF-8684863119",
  // "transaction_date": "2024-07-23T09:09:42.000Z",
  // "recipient": "Musediq Olamilekan",
  // "accountNumber": "1671280838",
  // "bankName": "Access Bank",
  // "amountSent": "1",
  // "amountReceived": "1200",
  // "recipientCurrency": "NGN",
  // "currency": "USD",
  // "conversionRate": "1200",
  // "transType": "Debit",
  // "recipientCode": "RCP_zhs45qrobyzzite",
  // "status": "success",
  // "description": "Funds transfer",
  // "user_id": 6,
  // "createdAt": "2024-07-23T09:09:42.000Z",
  // "updatedAt": "2024-07-23T09:09:42.000Z"

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Track Payments"}
          description={""}
          name={"Imperial Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <SideBar />
          <div className="flex-1 mb-14 max-w-[100vw] overflow-x-auto pt-8 pb-6 px-5 md:px-9 bg-[#F1F1F1]">
            <div className="flex items-center justify-between">
              <h4 className="font-[600] text-lg mb-1">Track Payments</h4>
            </div>

            {pending ? (
              <Loader />
            ) : (
              <div className="mt-5 ">
                <div className="flex w-[1043px] mb-6  flex-col  justify-between">
                  <div className="h-[75%] rounded-[20px] border-[#D1D1D1] border-[0.8px] p-5 bg-[#FFF]">
                    <div className="flex border-b-[#D1D1D1]  border-b-[0.5px] py-3">
                      <h4 className="font-[500] text-base text-[#707070] w-[80px] ">
                        No.
                      </h4>
                      <h4 className="font-[500] text-base text-[#707070] w-[250px]">
                        Recipient Name
                      </h4>
                      <h4 className="font-[500] text-base text-[#707070] w-[140px] ">
                        Amount Sent
                      </h4>
                      <h4 className="font-[500] text-base text-[#707070] w-[200px] ">
                        Recipient to Receive
                      </h4>
                      <h4 className="font-[500] text-base text-[#707070] w-[130px] ">
                        Status
                      </h4>
                      <h4 className="font-[500] text-base text-[#707070] w-[200px] ">
                        Payment Date
                      </h4>
                    </div>
                    <div className="overflow-y-auto h-[80%] ">
                      {data?.map((item) => {
                        return (
                          <div
                            key={item?.id}
                            onClick={() => {
                              navigate(`/payments-tracking/${item?.id}`);
                            }}
                            className="flex border-b-[#D1D1D1] cursor-pointer border-b-[0.5px] py-3"
                          >
                            <h4 className="font-[500] text-base text-[#000000] w-[80px] ">
                              {item?.id}.
                            </h4>
                            <h4 className="font-[500] text-base  text-[#000000] w-[250px] ">
                              {item?.paymentName || item?.description}
                            </h4>
                            <h4 className="font-[500] text-base text-[#000000] w-[140px]">
                              {currencySymbolMap[item?.currency]}
                              {formatAmount(item?.amountSent)}
                            </h4>
                            <h4 className="font-[500] text-base text-[#000000] w-[200px]">
                              {item?.recipientCurrency}{" "}
                              {formatAmount(item?.amountReceived)}
                            </h4>
                            <div className="font-[500] text-base text-[#000000] flex gap-2 items-center w-[130px] ">
                              {item?.status === "success" ? (
                                <>
                                  <div className="w-[10px] h-[10px] bg-[#4D9A00] rounded-full"></div>
                                  <h4 className="font-[500] text-base text-[#000000] ">
                                    Successful
                                  </h4>
                                </>
                              ) : (
                                <>
                                  <div className="w-[10px] h-[10px] bg-[#F0D411] rounded-full"></div>
                                  <h4 className="font-[500] text-base text-[#000000] ">
                                    Pending
                                  </h4>
                                </>
                              )}
                            </div>

                            <h4 className="cursor-pointer font-[500] text-base text-[#000000] w-[200px] ">
                              {timeformatter(
                                item?.transaction_date,
                                "formal",
                                true
                              )}
                            </h4>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* <Button
                  bg={"#192851"}
                  title={"Save"}
                  textStyles="font-[600] text-[18px]"
                  mainClasses="h-[56px] mx-auto "
                  // onClick={form.handleSubmit}
                  onClick={() => {
                    setShowSuccess(true);
                    // navigate("/payment");
                  }}
                  loading={form.isSubmitting}
                  // disabled={!form.dirty || !form.isValid || form.isSubmitting}
                /> */}
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TrackPayments;
