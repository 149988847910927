import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    error: null,
    pending: false,
    success: false,
    openSideMenu: false,
  },

  reducers: {
    closeModal: (state, action) => {
      state.whichModal = null;
      state.successModalData = {};
    },
    toggleSideMenu: (state) => {
      state.openSideMenu = !state.openSideMenu;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(laodPage.pending, (state, action) => {
    //   state.pending = true;
    //   state.error = null;
    // });
    // builder.addCase(laodPage.rejected, (state, action) => {
    //   state.pending = false;
    //   state.error = true;
    // });
    // builder.addCase(laodPage.fulfilled, (state, action) => {
    //   state.pending = false;
    //   state.error = null;
    //   state.success = true;
    // });
  },
});

export const { showModal, toggleSideMenu, showSucessModal, closeModal } =
  uiSlice.actions;
export default uiSlice.reducer;
