import { createAsyncThunk } from "@reduxjs/toolkit";
import { PROFILE_KYC } from "../../../config/apis";
import { Client } from "../../../config/client";
import { toast } from "react-toastify";

export const completeKYC = createAsyncThunk(
  "profileKYC/post",
  async ({ payload, cb, failed }) => {
    try {
      const { data } = await Client({
        method: "PUT",
        path: PROFILE_KYC,
        data: payload,
        contentType: "multipart/form-data",
      });
      toast.success("---");
      cb();

      // return data?.data;
    } catch (error) {
      failed();
      toast.error(error?.response?.data?.message || "Something went wrong");
      throw error;
    }
  }
);
