import { createSlice } from "@reduxjs/toolkit";
import { getProfile } from "../actions/profile/getProfile";
import { completeProfile } from "../actions/profile/completeProfile";
import { completeKYC } from "../actions/profile/completeKYC";
import { login } from "../actions/onboarding/login";
import { loginWithGoogle } from "../actions/onboarding/loginWithGoogle";
import { register } from "../actions/onboarding/register";
import { loginWithFacebook } from "../actions/onboarding/loginWithFacebook";

export const profileSlice = createSlice({
  name: "user",
  initialState: {
    error: null,
    pending: false,
    success: false,
    data: [],
    dataDetails: {},
    kyc: {},
  },

  reducers: {
    logOut: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.dataDetails = null;
      state.kyc = null;
    });
    builder.addCase(loginWithFacebook.fulfilled, (state, action) => {
      state.dataDetails = null;
      state.kyc = null;
    });
    builder.addCase(loginWithGoogle.fulfilled, (state, action) => {
      state.dataDetails = null;
      state.kyc = null;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.dataDetails = null;
      state.kyc = null;
    });
    builder.addCase(completeProfile.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(completeProfile.fulfilled, (state, action) => {
      state.dataDetails = action.payload;
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(completeProfile.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
    builder.addCase(completeKYC.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(completeKYC.fulfilled, (state, action) => {
      state.kyc = action.payload;
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(completeKYC.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
    builder.addCase(getProfile.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.dataDetails = action.payload;
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
  },
});

export const {} = profileSlice.actions;
export default profileSlice.reducer;
