import React, { useEffect } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import SideBar from "../Components/Bars/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../Components/Inputs/Button";
import { getTransaction } from "../store/actions/transactions/getTransaction";
import Loader from "../Components/Utils/Loader";
import { currencySymbolMap } from "../utils/data";
import { timeformatter } from "../utils/dateformatter";

const TransactionReceipt = () => {
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { pending, dataDetails } = useSelector((state) => state.transactions);

  useEffect(() => {
    if (id) {
      dispatch(getTransaction(id));
    } else {
      navigate(-2);
    }
  }, [id]);

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Transactions"}
          description={""}
          name={"Imperial Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <SideBar />
          <div className="flex-1 mb-14 max-w-[100vw] overflow-x-auto pt-6 pb-6 px-5 md:px-9 bg-[#F1F1F1]">
            <div className="flex items-center justify-between">
              <h4 className="font-[600] text-lg mb-1">Transaction Receipt</h4>
            </div>

            {pending ? (
              <Loader />
            ) : (
              <>
                <div className="mt-5 ">
                  <div className="flex max-w-[95%] flex-col min-h-[422px] bg-[#fff] mx:auto sm:max-w-[709px] mb-6 py-7 px-5 rounded-[17px] flex-wrap justify-center">
                    <div className="flex max-w-full mb-6  flex-wrap  justify-between">
                      <h4 className="font-[600] text-[16px] md:text-[18px] text-[#000]  ">
                        Amount Sent:
                      </h4>
                      <h4 className="font-[400] text-[16px] md:text-[18px] text-[#000]">
                        {currencySymbolMap[dataDetails?.currency]}
                        {dataDetails?.amountSent}
                      </h4>
                    </div>
                    <div className="flex max-w-full mb-6  flex-wrap  justify-between">
                      <h4 className="font-[600] text-[16px] md:text-[18px] text-[#000]  ">
                        Amount Received by the Recipient:
                      </h4>
                      <h4 className="font-[400] text-[16px] md:text-[18px] text-[#000]">
                        {dataDetails?.recipientCurrency}{" "}
                        {dataDetails?.amountReceived}
                      </h4>
                    </div>
                    <div className="flex max-w-full mb-6  flex-wrap  justify-between">
                      <h4 className="font-[600] text-[16px] md:text-[18px] text-[#000]  ">
                        Description:
                      </h4>
                      <h4 className="font-[400] text-[16px] md:text-[18px] text-[#000]">
                        {dataDetails?.description}
                      </h4>
                    </div>
                    <div className="flex max-w-full mb-6  flex-wrap  justify-between">
                      <h4 className="font-[600] text-[16px] md:text-[18px] text-[#000]  ">
                        Transaction Reference:
                      </h4>
                      <h4 className="font-[400] text-[16px] md:text-[18px] text-[#000]">
                        {dataDetails?.transaction_ref}
                      </h4>
                    </div>
                    <div className="flex max-w-full mb-6  flex-wrap  justify-between">
                      <h4 className="font-[600] text-[16px] md:text-[18px] text-[#000]  ">
                        Transaction Date:
                      </h4>
                      <h4 className="font-[400] text-[16px] md:text-[18px] text-[#000]">
                        {timeformatter(
                          dataDetails?.transaction_date,
                          "formal",
                          true
                        )}
                      </h4>
                    </div>
                    <div className="flex max-w-full mb-6  flex-wrap  justify-between">
                      <h4 className="font-[600] text-[16px] md:text-[18px] text-[#000]  ">
                        Bank Name:
                      </h4>
                      <h4 className="font-[400] text-[16px] md:text-[18px] text-[#000]">
                        {dataDetails.bankName}
                      </h4>
                    </div>
                    <div className="flex max-w-full mb-6  flex-wrap  justify-between">
                      <h4 className="font-[600] text-[16px] md:text-[18px] text-[#000]  ">
                        Bank Account:
                      </h4>
                      <h4 className="font-[400] text-[16px] md:text-[18px] text-[#000]">
                        {dataDetails.accountNumber}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="">
                  <Button
                    bg={"#192851"}
                    title={"Download"}
                    textStyles="font-[600]  text-[18px]"
                    mainClasses="h-[56px]  "
                    onClick={() => {
                      // setShowSuccess(true);
                    }}
                    // loading={form.isSubmitting}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TransactionReceipt;
