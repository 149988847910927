import * as Yup from "yup";

export const signupSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  fullName: Yup.string().required("Required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  confirmPassword: Yup.string()
    .when("password", (password, schema) => {
      if (password) return schema.required("Confirm Password is required");
    })
    .oneOf([Yup.ref("password")], "Passwords must match"),
});
export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),

  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
});

export const paymentMethodSchema = Yup.object().shape({
  accountType: Yup.object().required("This field is required."),

  expiry: Yup.string()
    .required("This field is required.")
    .length(7, "Invalide Expiry"),
  cvv: Yup.string()
    .required("This field is required.")
    .min(3, "Invalide CVV")
    .max(4, "Invalide CVV"),
  // pin: Yup.string()
  //   .required("This field is required.")
  //   .length(4, "Invalide pin"),
  cardNumber: Yup.string()
    .required("This field is required.")
    .length(19, "This field requires a sixteen-digit number."),
});

export const resetPasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required("Current Password is required")
    .min(8, "Password must be at least 8 characters"),

  newPassword: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  confirmPassword: Yup.string()
    .when("newPassword", (newPassword, schema) => {
      if (newPassword) return schema.required("Confirm Password is required");
    })
    .oneOf([Yup.ref("newPassword")], "Passwords must match"), // Corrected field name
});

export const fundWalletSchema = Yup.object().shape({
  amount: Yup.string().required("This field is required"),
});
export const beneficiariesSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  fullName: Yup.string()
    .matches(/^[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/, "Invalid Full Name")
    .required("Name is required")
    .test("regex test", `Invalid Full Name`, (val) => {
      const regExp = /[a-zA-Z]/;
      return regExp.test(val);
    }),

  accountNumber: Yup.string().required("This field is required"),
  bankName: Yup.object().required("This field is required."),
  swiftCode: Yup.string().required("This field is required."),
});
export const profileSchema = Yup.object().shape({
  fullName: Yup.string()
    .matches(/^[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/, "Invalid Full Name")
    .required("Name is required")
    .test("regex test", `Invalid Full Name`, (val) => {
      const regExp = /[a-zA-Z]/;
      return regExp.test(val);
    }),

  phone: Yup.string().required("This field is required."),
  email: Yup.string().email().required("This field is required."),
  dateOfBirth: Yup.date().required("This field is required."),
  gender: Yup.object().required("This field is required."),

  idNumber: Yup.string().required("This field is required."),
  country: Yup.object().required("This field is required."),
});
export const driversSchema = Yup.object().shape({
  file: Yup.mixed().required("This field is required."),

  homeAddress: Yup.string().required("This field is required."),
});
export const passportSchema = Yup.object().shape({
  file: Yup.mixed().required("This field is required."),

  number: Yup.string().required("This field is required."),
});
export const boiSchema = Yup.object().shape({
  file: Yup.mixed().required("This field is required."),

  homeAddress: Yup.string().required("This field is required."),
});

export const recurringSchema = Yup.object().shape({
  // password: Yup.string()
  //   .required("Password is required")
  //   .min(8, "Password must be at least 8 characters"),
  paymentName: Yup.string()
    .matches(/^[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/, "Invalid Name")
    .required("Name is required")
    .test("regex test", `Invalid Name`, (val) => {
      const regExp = /[a-zA-Z]/;
      return regExp.test(val);
    }),

  recipientAccountNumber: Yup.string().required("This field is required"),
  recipientBankName: Yup.object().required("This field is required."),
  frequency: Yup.string().required("This field is required."),
  currency: Yup.string().required("This field is required."),
  recipientCurrency: Yup.string().required("This field is required."),
  swiftCode: Yup.string().required("This field is required."),
  amount: Yup.string().required("This field is required."),
  startDate: Yup.date().required("This field is required."),
  endDate: Yup.date().required("This field is required."),
});
export const transferSchema = Yup.object().shape({
  recipient: Yup.string()
    .matches(/^[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/, "Invalid Name")
    .required("Name is required")
    .test("regex test", `Full Name`, (val) => {
      const regExp = /[a-zA-Z]/;
      return regExp.test(val);
    }),

  accountNumber: Yup.string().required("This field is required"),

  bankName: Yup.object().required("This field is required."),
  amount: Yup.string().required("This field is required."),

  swiftCode: Yup.string().required("This field is required."),
  recipientCurrency: Yup.string().required("This field is required."),
  currency: Yup.string().required("This field is required."),
});

// export const _step3Schema = Yup.object().shape({
//   phone: Yup.string()
//     .required("Phone field is required")
//     .test("regex test", `Invalid phone`, (val) => {
//       const regExp = /[a-zA-Z]/;
//       return !regExp.test(val);
//     })

//     .test("empty string test", "Field can not be empty", (val) => {
//       return val.trim() !== "";
//     }),

//   last_name: Yup.string().required("Required"),

//   dob: Yup.date().required("Required"),
//   country: Yup.object().required("Required"),
//   gender: Yup.object().required("Required"),
//   state: Yup.object().required("Required"),
//   city: Yup.object().required("Required"),
//   postal_code: Yup.string()
//     .length(6, "This field requires a six-digit number.")
//     .required("Required"),
//   address: Yup.string().required("Required"),
// });
// export const _supportSchema = Yup.object().shape({
//   first_name: Yup.string().required("Required"),
//   last_name: Yup.string().required("Required"),
//   id: Yup.mixed().required("Required"),
//   dob: Yup.date().required("Required"),

//   gender: Yup.object().required("Required"),
// });
// export const _loginSchema = Yup.object().shape({
//   username: Yup.string()
//     .email("Enter a valid email")
//     .required("Email is required"),
//   password: Yup.string()
//     .required("Password is required")
//     .min(8, "Password must be at least 8 characters"),
// });

// export const _createPasswordSchema = Yup.object().shape({
// password: Yup.string()
//   .required("Password is required")
//   .min(8, "Password must be at least 8 characters"),
// password_confirmation: Yup.string()
//   .when("password", (password, schema) => {
//     if (password) return schema.required("Confirm Password is required");
//   })
//   .oneOf([Yup.ref("password")], "Passwords must match"),
// });
