import React, { useRef, useState } from "react";
import { BsUpload } from "react-icons/bs";
import Loader from "../Utils/Loader";

const FilePicker = ({ form, loading, id, type }) => {
  const [file, setFile] = useState(null);

  const imageRef = useRef();

  const handleImageChange = async (e) => {
    if (!e?.dataTransfer?.files[0] && !e?.target?.files[0]) {
      return;
    }
    const files = e?.dataTransfer?.files || e?.target?.files;
    const convertedFiles = files[0];

    form?.setFieldValue(`${id}-name`, convertedFiles.name);

    // if (!/(gif|jpe?g|png|jpg|svg|jpeg|pdf)$/i.test(convertedFiles.name)) {
    //   // dispatch(
    //   //   showErrorModal(
    //   //     "Invalid file type. Only PNG, jpg, GIF, JPEG, SVG, and PDF files are accepted."
    //   //   )
    //   // );
    //   return;
    // }
    if ((e.target.files[0].size / 1024 / 1024).toFixed(4) > 5) {
      // dispatch(showErrorModal("File size is too big (max. 1.5MB)"));
      return;
    }

    // const fileReader = new FileReader();
    // fileReader.readAsDataURL(convertedFiles);
    // fileReader.addEventListener("load", (result) => {
    //   setMyImagePreview(result.target.result);
    // });
    setFile(convertedFiles.name);
    form?.setFieldValue(id, convertedFiles);
  };

  return (
    <>
      <div
        onClick={() => {
          imageRef.current.click();
        }}
        className="flex relative items-center mt-5 mb-6"
      >
        {type === 2 ? (
          <div className="border-[0.5px] border-[#707070] items-center justify-between px-4 rounded-[8px] mt-4 w-full sm:w-[371px] h-[45px] flex">
            {loading ? (
              <Loader />
            ) : (
              <>
                <div>{file}</div>
                <BsUpload size={"26px"} color={"#000"} />
              </>
            )}
          </div>
        ) : (
          <div className="border-[0.5px] border-[#707070] items-center justify-center px-4 rounded-[8px]  w-full sm:w-[371px] h-[77px] flex">
            {loading ? (
              <Loader />
            ) : file ? (
              file
            ) : (
              <BsUpload size={"51px"} color={"#000"} />
            )}
          </div>
        )}
        <input
          id={"id"}
          type={"file"}
          accept="*"
          ref={imageRef}
          className={`hidden`}
          onChange={(e) => {
            handleImageChange(e);
            // onChange(e?.target?.value);
          }}
        />
      </div>
    </>
  );
};

export default FilePicker;
