import React from "react";

const WhyUs = () => {
  const reasons = [
    {
      image: require("../../Assets/Icons/fast-response.png"),
      title: "Speed",
      details:
        "Say goodbye to long processing times and delays. Our money transfer app is designed for speed, ensuring that transactions are processed quickly and funds are transferred to the recipient's account in record time.",
    },
    {
      image: require("../../Assets/Icons/cyber-security.png"),
      title: "Security",
      details:
        "With robust security measures in place, users can have peace of mind knowing that their financial information is protected from unauthorized access and fraud.",
    },
  ];
  const moreReasons = [
    {
      image: require("../../Assets/Icons/Vector.png"),
      title: "No hidden charges",
      details: "Transparent transactions: No hidden charges.",
    },
    {
      image: require("../../Assets/Icons/customer-care 1.png"),
      title: "24/7 In-app support",
      details:
        "Your questions answered, issues resolved, and feedback heard, all at your convenience. Expert assistance just a message away.",
    },
  ];

  return (
    <div className="bg-[#FFF] font-mons pt-4 pb-4 md:pb-16">
      <div className="container  mx-auto lg:min-h-[628px] ">
        <div className="mx-auto max-w-[482px] px-4">
          <h3 className="text-[#1C2C57] text-[37px] font-[700] text-center">
            Why people prefer us?
          </h3>
          <h6 className="color-[#000] mt-1 leading-[25.5px] text-[15px] font-[400] text-center">
            Discover why users trust us for seamless transactions, unbeatable
            security, and transparent service.
          </h6>
        </div>
        <div className="flex flex-col mt-5 lg:flex-row justify-between gap-4 items-center ">
          <img
            className="w-[345.02px] h-[359.1px] flex-1 object-contain"
            src={require("../../Assets/Images/Why.png")}
            alt="why us"
          />

          <div className="flex flex-col gap-3 lg:mb-5 sm:flex-row justify-between items-center flex-wrap flex-1">
            {reasons?.map((reason, index) => {
              return (
                <div
                  key={index}
                  className="w-[292px] flex md:block items-center flex-col h-[260px]"
                >
                  <img
                    className="w-[74px] h-[74px]"
                    src={reason.image}
                    alt="reason"
                  />
                  <h3 className="text-[#1C2C57] text-center md:text-left text-[25px] font-[700]">
                    {reason?.title}
                  </h3>
                  <h6 className="color-[#000] mt-1 text-center md:text-left leading-[25.5px] text-[15px] font-[400] ">
                    {reason.details}
                  </h6>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col gap-3 sm:flex-row justify-between items-center flex-wrap flex-1">
            {moreReasons?.map((reason, index) => {
              return (
                <div
                  key={index}
                  className="w-[292px] flex md:block items-center flex-col h-[260px]"
                >
                  <img
                    className="w-[74px] h-[74px]"
                    src={reason.image}
                    alt="reason"
                  />
                  <h3 className="text-[#1C2C57] text-[25px] text-center md:text-left font-[700]">
                    {reason?.title}
                  </h3>
                  <h6 className="color-[#000] mt-1 leading-[25.5px] text-center md:text-left text-[15px] font-[400] ">
                    {reason.details}
                  </h6>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
