import { createAsyncThunk } from "@reduxjs/toolkit";
import { RECURRING } from "../../../config/apis";
import { Client } from "../../../config/client";
import { toast } from "react-toastify";

export const editRecurring = createAsyncThunk(
  "recurring/put",
  async (payload) => {
    try {
      const {
        data: { user },
      } = await Client({
        method: "PUT",
        path: RECURRING,
        data: payload,
      });
      toast.success("--");
      return user;
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      throw error;
    }
  }
);
