import React, { useState } from "react";
import Loader from "../Utils/Loader";
import { IoCaretDownSharp } from "react-icons/io5";
import DropDownBackDrop from "../Utils/DropDownBackDrop";

const ButtonDropDown = ({
  mainClasses,
  title,
  dropDownClasses,
  height,
  bg,
  color,
  leftIcon,
  rightIcon,
  textStyles,
  onClick,
  loading,
  disabled,
}) => {
  const [showDropDown, setDropDownState] = useState(false);

  const handleToggleDropDown = () => {
    setDropDownState((prev) => !prev);
  };

  return (
    <>
      {showDropDown ? (
        <DropDownBackDrop handlehideDropDown={handleToggleDropDown} />
      ) : (
        <></>
      )}
      <div
        onClick={disabled ? null : onClick}
        className={`flex justify-center  relative border-[1px]  my-3 gap-2 items-center px-3  w-[243px] h-[48px] rounded-[12px] transition-all ease-in-out duration-300  ${
          disabled
            ? "cursor-not-allowed"
            : `hover:bg-transparent cursor-pointer group hover:border-[${bg}] bg-[${bg}]`
        } ${mainClasses}`}
        style={{
          height,
          backgroundColor: leftIcon || rightIcon || disabled ? bg : null,
          opacity: disabled ? 0.4 : null,
        }}
      >
        <div
          className="flex justify-center gap-2 items-center"
          onClick={handleToggleDropDown}
        >
          {loading ? (
            <Loader />
          ) : (
            <>
              <h4
                className={`text-[#FFF] group-hover:text-[${bg}] font-[500] text-[14px] transition-all ease-in-out duration-300 ${textStyles}`}
                style={{
                  color,
                }}
              >
                {title}
              </h4>
              <IoCaretDownSharp size={"10px"} color="#000" />
            </>
          )}
        </div>

        {showDropDown && (
          <div
            style={{
              backgroundColor: bg || "#FFF",
            }}
            className={` ${
              showDropDown
                ? `flex absolute z-[3]  min-h-6 flex-col  transition-all ease-in-out duration-100  overflow-hidden  rounded-b-[6px] top-0 ${""}`
                : "hidden w-[0px]  transition-all ease-in-out duration-100 "
            } ${dropDownClasses}`}
          >
            <div className=" flex flex-col overflow-y-auto max-h-[32rem]">
              {[1, 2, 3, 4]?.map((curr, index) => (
                <div
                  onClick={() => {
                    // setSelected(curr);

                    setDropDownState(false);
                  }}
                  key={index}
                  className={` h-[38px] hover:bg-[#E2E1E1] border-b border-b-[#D1D1D1] transition-all ease-in-out duration-100  cursor-pointer flex justify-between items-center px-3`}
                >
                  <h4
                    className={`text-[#FFF]  font-[500] text-[14px] transition-all ease-in-out duration-300 ${textStyles}`}
                    style={{
                      color,
                    }}
                  >
                    {title}
                  </h4>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ButtonDropDown;
