import React, { useEffect, useState } from "react";
import Header from "../Components/Bars/Header";
import SEO from "../Components/Utils/Seo";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { formHandler } from "../utils/formhandler";
import SuccessMessage from "../Components/Utils/SuccessMessage";
import TextInput from "../Components/Inputs/TextInput";
import Button from "../Components/Inputs/Button";
import { resetPasswordSchema } from "../utils/validationSchemas";
import { createPassword } from "../store/actions/onboarding/createPassword";

const ChangePassword = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const form = useFormik({
    initialValues: {
      oldPassword: "",

      newPassword: "",
      confirmPassword: "",
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { confirmPassword, ...rest } = values;
      const data = {
        ...rest,
      };

      dispatch(
        createPassword({
          cb: () => {
            // resetForm();
            setSubmitting(false);
            setShowSuccess(true);
            // setShowSuccess(true);
          },
          payload: data,
          failed: () => {
            setSubmitting(false);
          },
        })
      );
    },
  });

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Change Password"}
          description={""}
          name={"Imperial Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <div className="flex-1  max-w-[100vw] overflow-x-auto pt-4 pb-6 px-5 md:px-9 bg-[#F1F1F1]">
            <h4
              onClick={() => {
                navigate(-1);
              }}
              className="cursor-pointer text-[21px] sm:text-[25px] font-[600] w-[646px] max-w-[100%] mt-6 mb-3 mx-auto text-[#000]"
            >
              Settings / Change Password
            </h4>
            <div className="w-[646px] max-w-[100%] px-5 py-5 sm:px-8 mx-auto bg-[#FFFFFF]">
              <div className="w-full items-center flex flex-col">
                <div className="flex w-full flex-col my-6 items-center gap-4">
                  <TextInput
                    title={"Current Password"}
                    {...formHandler("oldPassword", form)}
                    type={"password"}
                    placeholder={"**************"}
                    mainClasses={"!w-full sm:max-w-[371px]"}
                  />
                  <TextInput
                    title={"New Password"}
                    {...formHandler("newPassword", form)}
                    type={"password"}
                    placeholder={"**************"}
                    mainClasses={"!w-full sm:max-w-[371px]"}
                  />
                  <TextInput
                    title={"Confirm New Password"}
                    {...formHandler("confirmPassword", form)}
                    type={"password"}
                    placeholder={"**************"}
                    mainClasses={"!w-full sm:max-w-[371px]"}
                  />
                </div>

                <Button
                  bg={"#192851"}
                  title={"Change Password"}
                  textStyles="font-[600]  text-[18px]"
                  mainClasses="h-[56px]  "
                  onClick={form.handleSubmit}
                  loading={form.isSubmitting}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {showSuccess ? (
        <SuccessMessage
          handleClose={() => {
            setShowSuccess(false);
            // navigate("/sign-in");
          }}
          title={"Password change successfully"}
          message={
            "You have successfully change password. Please use the new password when Sign in."
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ChangePassword;
