import React from "react";

const PaymentCards = ({ setSelected, selected, index, opt }) => {
  const cardNumber = opt?.cardNumber;
  const type =
    cardNumber?.startsWith(2) || cardNumber?.startsWith(5)
      ? "MasterCard"
      : cardNumber?.startsWith(4)
      ? "VISA"
      : cardNumber?.startsWith(3)
      ? "AMEX"
      : null;

  return (
    <div
      onClick={() => {
        setSelected(index);
      }}
      className="flex items-center my-[10px] cursor-pointer justify-between border-[0.5px] rounded-lg bg-[rgba(24,115,185,0.5)] h-[68.34px] w-full sm:w-[80%] max-w-full md:w-[626.77px]  bg-gradient-to-b from-[rgba(255,255,255,1)] to-[rgba(233,239,255,1)] px-3 sm:px-7 "
    >
      <div className="flex gap-6 items-center ">
        <div
          className={`w-[10px] h-[10px] ${
            selected === index
              ? "bg-[rgba(24,200,20,1)]"
              : "bg-[#FFF] border-[0.2px] border-[#DEDDDD]"
          } rounded-full`}
        ></div>

        {type === "VISA" ? (
          <img
            className="h-[20px] sm:h-[31px] cursor-pointer "
            src={require("../../Assets/Images/visa.png")}
          />
        ) : type === "AMEX" ? (
          <img
            className=" h-[20px] sm:h-[31px] cursor-pointer "
            src={require("../../Assets/Images/amex.png")}
          />
        ) : type === "MasterCard" ? (
          <img
            className="h-[20px] sm:h-[31px] cursor-pointer "
            src={require("../../Assets/Images/mastercard.png")}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="flex flex-col  items-end justify-center">
        <h4 className="text-[14px] text-[#000] font-400] ">{`**** **** **** ${opt.cardNumber?.slice(
          -4
        )}`}</h4>
        <h4 className="text-[14px] text-[#000] font-[500] ">{type}</h4>
      </div>
    </div>
  );
};

export default PaymentCards;
