import React, { useEffect, useState } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { FaChevronRight } from "react-icons/fa6";

const Refer = () => {
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const form = useFormik({
    initialValues: {
      fullName: "",
      bankName: "",
    },
    // validationSchema: signupSchema,
    onSubmit: (values, { setSubmitting }) => {
      const data = {
        ...values,
      };

      // dispatch(register({ navigate, data, setSubmitting }));
    },
  });

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Refer"}
          description={""}
          name={"Imperial Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <div className="flex-1 justify-center items-end max-w-[100vw] flex overflow-x-auto pt-4 pb-6 px-5 md:px-9">
            <div className="sm:w-[416px] h-full">
              <h4 className="font-[600] text-[40px] sm:text-[72px] leading-tight text-[#000000]">
                Refer a <br /> Friend
              </h4>
              <h4 className="font-[500] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                Share referral code with your friends and family and get up to
                10% on their first transfer
              </h4>
              <div className="flex my-3 flex-col min-h-[240px] mx:auto sm:max-w-[384px] mb-4 py-7  flex-wrap justify-center">
                <div className="flex  gap-2 ">
                  <div className="flex flex-col  justify-center items-center">
                    <div
                      style={{
                        backgroundColor: "#E7EEFF",
                      }}
                      className="rounded-full h-[68px]  w-[68px]  flex justify-center items-center"
                    >
                      <img
                        src={require("../Assets/Icons/refer1.png")}
                        className="w-[36px] h-[36px]"
                        alt="refer"
                      />
                    </div>

                    <div
                      style={{
                        borderColor: "#000000",
                        borderWidth: "0.8px",
                        borderStyle: "dashed",
                        height: "56px",
                      }}
                      className="  w-[0px]  "
                    ></div>
                  </div>
                  <div className=" flex-col h-[68px] justify-center flex gap-[2px]">
                    <h3 className="text-[#000] font-[500] text-[14px] sm:text-base ">
                      Invite your friend to signup
                    </h3>
                  </div>
                </div>
                <div className="flex gap-2 ">
                  <div className="flex flex-col  justify-center items-center">
                    <div
                      style={{
                        backgroundColor: "#E7EEFF",
                      }}
                      className="rounded-full h-[68px]  w-[68px]  flex justify-center items-center"
                    >
                      <img
                        src={require("../Assets/Icons/refer2.png")}
                        className="w-[36px] h-[36px]"
                        alt="refer"
                      />
                    </div>

                    <div
                      style={{
                        borderColor: "#000000",
                        borderWidth: "0.8px",
                        borderStyle: "dashed",
                        height: "56px",
                      }}
                      className="  w-[0px]  "
                    ></div>
                  </div>
                  <div className=" flex-col h-[68px] justify-center flex gap-[2px]">
                    <h3 className="text-[#000] font-[500] text-[14px] sm:text-base ">
                      Your friend signs up
                    </h3>
                  </div>
                </div>
                <div className="flex gap-2 ">
                  <div className="flex flex-col  justify-center items-center">
                    <div
                      style={{
                        backgroundColor: "#E7EEFF",
                      }}
                      className="rounded-full h-[68px]  w-[68px]  flex justify-center items-center"
                    >
                      <img
                        src={require("../Assets/Icons/refer3.png")}
                        className="w-[36px] h-[36px]"
                        alt="refer"
                      />
                    </div>
                  </div>
                  <div className=" flex-col h-[68px] justify-center flex gap-[2px]">
                    <h3 className="text-[#000] font-[500] text-[14px] sm:text-base ">
                      You and your friend get rewarded
                    </h3>
                  </div>
                </div>
              </div>
              <div className="my-3 ">
                <h4 className="font-[500] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  YOUR REFERRAL CODE
                </h4>
                <div className="flex items-center mt-1 gap-2">
                  <div className="bg-[rgba(217,217,217,0.44)] cursor-pointer rounded-[10px] flex justify-center items-center w-[176px] h-[41px]">
                    <h4 className="font-[500] mt-1 text-[22px] sm:text-[24px] text-[#000000]">
                      {user?.referralCode}
                    </h4>
                  </div>
                  <img
                    src={require("../Assets/Icons/Copy.png")}
                    className="w-[20px] cursor-pointer h-[20px]"
                    alt="refer"
                  />
                </div>
              </div>
            </div>
            <div>
              <img
                src={require("../Assets/Icons/refer-aside.png")}
                className="w-[571px] h-[571px] object-contain"
                alt="refer"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Refer;
