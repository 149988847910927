import { createSlice } from "@reduxjs/toolkit";
import { getRecurrings } from "../actions/recurring/getRecurrings";
import { getRecurring } from "../actions/recurring/getRecurring";
import { deleteRecurring } from "../actions/recurring/deleteRecurring";
import { editRecurring } from "../actions/recurring/editRecurring";
import { createRecurring } from "../actions/recurring/createRecurring";
import { getPayment } from "../actions/payments/getPayment";
import { getPayments } from "../actions/payments/getPayments";
import { login } from "../actions/onboarding/login";
import { loginWithFacebook } from "../actions/onboarding/loginWithFacebook";
import { loginWithGoogle } from "../actions/onboarding/loginWithGoogle";
import { register } from "../actions/onboarding/register";

export const trackingSlice = createSlice({
  name: "tracking",
  initialState: {
    error: null,
    pending: false,
    success: false,
    data: [],
    dataDetails: {},
  },

  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.data = [];
      state.dataDetails = {};
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.data = [];
      state.dataDetails = {};
    });
    builder.addCase(loginWithGoogle.fulfilled, (state, action) => {
      state.data = [];
      state.dataDetails = {};
    });
    builder.addCase(loginWithFacebook.fulfilled, (state, action) => {
      state.data = [];
      state.dataDetails = {};
    });
    builder.addCase(getPayments.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(getPayments.fulfilled, (state, action) => {
      state.data = action.payload;
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(getPayments.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
    builder.addCase(getPayment.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(getPayment.fulfilled, (state, action) => {
      state.dataDetails = action.payload;
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(getPayment.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
    builder.addCase(deleteRecurring.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(deleteRecurring.fulfilled, (state, action) => {
      state.data = state.data?.filter((ben) => ben?.id != action.payload);
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(deleteRecurring.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
    builder.addCase(editRecurring.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(editRecurring.fulfilled, (state, action) => {
      // state.data = state.data;
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(editRecurring.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
    builder.addCase(createRecurring.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(createRecurring.fulfilled, (state, action) => {
      // state.data = state.data;
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(createRecurring.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
  },
});

export const {} = trackingSlice.actions;
export default trackingSlice.reducer;
