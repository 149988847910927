import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import { TbCaptureFilled } from "react-icons/tb";
import DropDownBackDrop from "../Utils/DropDownBackDrop";

const Selfie = ({ form, id }) => {
  const [file, setFile] = useState(null);
  const [showWebCam, setShowWebcam] = useState(false);
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  const webcamRef = useRef(null);
  const capture = useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot();

    const bolb = dataURItoBlob(imageSrc);
    // const a = document.createElement("a");
    // a.download = "my-file.txt";
    // a.href = URL.createObjectURL(imageSrc);
    // a.addEventListener("click", (e) => {
    //   setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    // });
    // a.click();
    // setFile()

    form?.setFieldValue(id, bolb);
    setShowWebcam(false);
  }, [webcamRef]);

  const handleToggleBackDrop = () => {
    setShowWebcam((prev) => !prev);
  };

  return (
    <>
      {showWebCam ? (
        <DropDownBackDrop handlehideDropDown={handleToggleBackDrop} />
      ) : (
        <></>
      )}
      <div
        onClick={() => {
          if (!showWebCam) {
            setShowWebcam(true);
          }
        }}
        className="border-[0.5px] relative border-[#707070] items-center justify-between px-4 rounded-[8px] mt-1 w-full sm:w-[371px] h-[45px] flex"
      >
        <div>{file}</div>
        {showWebCam ? (
          <div className="flex flex-col bg-[rgb(241,241,241,0.8)] rounded-[4px] relative items-center">
            <Webcam
              audio={false}
              height={720}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={1280}
              className="z-50 rounded-[4px]"
              videoConstraints={videoConstraints}
            />
            <TbCaptureFilled
              onClick={capture}
              color="#FFF"
              className="w-[36px] cursor-pointer absolute bottom-3 z-50 h-[36px] "
            />
          </div>
        ) : (
          <img
            src={require("../../Assets/Icons/selfie.png")}
            alt="selfie"
            className="w-[24px] h-[24px] "
          />
        )}
      </div>
    </>
  );
};

export default Selfie;
