import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Loader from "../Utils/Loader";

const TextInput = ({
  value,
  onChange,
  loading,
  title,
  placeholder,
  disabled,
  mainClasses,
  inputClasses,
  defaultValue,
  id,
  type,
  error,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleNumbers = (e) => {
    let text = e.target.value;
    text = text.replace(/\D/g, "");

    if (id === "cardNumber") {
      text = text.length
        ? text.split("").reduce((acc, curr, ind) => {
            if (ind !== 0 && !(ind % 4)) acc += " ";
            return acc + curr;
          })
        : text;

      e.target.value = text;
      onChange(e);
    } else if (id === "expiry") {
      text = text.length
        ? text.split("").reduce((acc, curr, ind) => {
            if (ind !== 0 && !(ind % 2)) acc += " / ";
            return acc + curr;
          })
        : text;

      e.target.value = text;
      onChange(e);
    } else {
      onChange(e);
    }
  };

  return (
    <div className={`font-mons w-[367px] ${mainClasses}`}>
      <h4 className="text-[#707070] font-[400] text-[14px] mb-1 leading-[21.94px]">
        {title}
      </h4>
      <div className="flex relative items-center">
        <input
          value={value}
          onChange={handleNumbers}
          id={id}
          name={id}
          type={type === "password" && showPassword ? "text" : type}
          placeholder={placeholder}
          disabled={disabled ? disabled : false}
          className={`flex w-full disabled:cursor-not-allowed text-[13px] outline-none disabled:bg-black-10 placeholder:text-black-50 focus:border-[2px] focus:border-black-90 invalid:[&:not(:placeholder-shown):not(:focus)]:border-[2px] invalid:[&:not(:placeholder-shown):not(:focus)]:border-error-light transition-all ease-in-out duration-100 h-[44px] bg-[#FF] border-[0.5px] border-[#999999] rounded-[8px] px-2 md:px-4 ${inputClasses}`}
          defaultValue={defaultValue}

          // onClick={(e) => e.stopPropagation()}
        />
        {type === "password" ? (
          <>
            {showPassword ? (
              <AiOutlineEye
                className="cursor-pointer absolute right-[15px]"
                color="rgba(153,153,153,0.64)"
                size={"20px"}
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <AiOutlineEyeInvisible
                className="cursor-pointer absolute right-[15px]"
                color="rgba(153,153,153,0.64)"
                size={"20px"}
                onClick={() => setShowPassword(true)}
              />
            )}
          </>
        ) : (
          <></>
        )}

        {loading ? (
          <div className="w-full h-full absolute top-0 flex justify-center items-center">
            <Loader size={"small"} />
          </div>
        ) : (
          <></>
        )}
      </div>
      {error && (
        <h6 className=" text-[0.75rem] mt-[6px] mb-2 text-error text-left w-full">
          {`${error}`}
        </h6>
      )}
    </div>
  );
};

export default TextInput;
