import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CHANGE_PASSWORD,
  PAYMENT_METHOD,
  PAYMENTS,
} from "../../../config/apis";
import { Client } from "../../../config/client";
import { toast } from "react-toastify";

export const createPassword = createAsyncThunk(
  "changPassword/post",
  async ({ payload, cb, failed }) => {
    try {
      await Client({
        method: "POST",
        path: CHANGE_PASSWORD,
        data: payload,
      });
      cb();
    } catch (error) {
      failed();
      toast.error(error?.response?.data?.message || "Something went wrong");
      throw error;
    }
  }
);
