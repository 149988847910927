import { createAsyncThunk } from "@reduxjs/toolkit";
import { PAYMENTS } from "../../../config/apis";
import { Client } from "../../../config/client";
import { toast } from "react-toastify";

export const getPayment = createAsyncThunk("payment/get", async (id) => {
  try {
    const { data } = await Client({
      method: "GET",
      path: `${PAYMENTS}/${id}`,
    });

    return data?.data;
  } catch (error) {
    // toast.error(error?.response?.data?.message || "Something went wrong");
    throw error;
  }
});
