import React, { useEffect, useState } from "react";

import {
  PieChart,
  Pie,
  Cell,
  Sector,
  ResponsiveContainer,
  Tooltip,
  Label,
} from "recharts";

const CustomPiechart = ({
  width,
  height,
  innerradius,
  outerradius,
  hasTooltop,
  centeredLabelContent,
  pieData = [],
  hasCenteredLabel,
}) => {
  const COLORS = [
    "#85B6FF",
    "#4ECB71",
    "#FFD233",
    "#FF9A62",
    "#D99BFF",
    "#E4A951",
  ];

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  const CustomTooltip = ({ payload }) => {
    return (
      <div>
        <div className="ant-popover-arrow" />
        <div className="h-[40px] px-2 py-1 min-w-[102px] w-max bg-[rgba(26,26,26,1)]">
          {/* <b>{payload?.[0]?.payload?.name}</b> */}
          {/* <span className={""}> */}
          <p className="text-[rgba(255,255,255,1)] font-[500] text-xs font-sf_pro_text">
            {payload?.[0]?.payload?.value}
          </p>
          <p className="text-[rgba(255,255,255,1)] font-[400] text-[10px] font-sf_pro_text">
            {payload?.[0]?.payload?.name}
          </p>
        </div>
      </div>
    );
  };

  const [current, setCurrent] = useState(null);

  return (
    <ResponsiveContainer
      // width={width}
      // height={height}
      height={"100%"}
      width={"100%"}
      style={{ outline: "none", border: "none" }}
    >
      <PieChart
        width={500}
        height={500}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        style={{ outline: "none", border: "none" }}
      >
        <Pie
          // cx="95%"
          // cy="95%"
          data={pieData}
          innerRadius={innerradius}
          activeIndex={0}
          // outerRadius={outerradius}
          // paddingAngle={0}
          dataKey={"value"}
          // isAnimationActive={false}
          style={{ outline: "none", border: "none" }}
        >
          {pieData.map((entry, index) => (
            <Cell
              onClick={() => {
                setCurrent(index);
              }}
              strokeWidth={index === current ? -2 : 0.7}
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
              style={{
                outline: "none",
                border: "none",
                scale: index === current ? 1.01 : 1,
              }}
            />
          ))}
          {hasCenteredLabel ? (
            <Label value="any text" position="center" />
          ) : (
            <></>
          )}
        </Pie>
        {hasTooltop ? (
          <Tooltip
            content={<CustomTooltip />}
            // coordinate={{ x: 100, y: 140 }}
          />
        ) : (
          <></>
        )}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CustomPiechart;
