import { createAsyncThunk } from "@reduxjs/toolkit";
import { RECURRING } from "../../../config/apis";
import { Client } from "../../../config/client";
import { toast } from "react-toastify";

export const deleteRecurring = createAsyncThunk(
  "recurring/delete",
  async ({ id, cb, failed }) => {
    try {
      const { data } = await Client({
        method: "DELETE",
        path: `${RECURRING}/${id}`,
      });
      cb();
      return id;
    } catch (error) {
      failed();
      toast.error(error?.response?.data?.message || "Something went wrong");
      throw error;
    }
  }
);
