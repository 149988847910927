import React, { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import DropDownBackDrop from "../Utils/DropDownBackDrop";

const CurrencyComponent = ({
  value,
  onChange,
  title,
  mainStyle,
  position,
  titleStyle,
  id,
  setSelected,
  inputStyles,

  defaultCurrency,
  onChangeCurrency,
  dropDownStyles,
  currency,
  items,
}) => {
  const [showDropDown, setDropDownState] = useState(false);

  const handleToggleDropDown = () => {
    setDropDownState((prev) => !prev);
  };

  return (
    <>
      {showDropDown ? (
        <DropDownBackDrop handlehideDropDown={handleToggleDropDown} />
      ) : (
        <></>
      )}
      <div className="relative">
        <div className={`w-full cursor-pointer mb-5 ${mainStyle}`}>
          <h4
            className={`text-[14px] md:text-base text-[#FFF] leading-5 mb-1 font-[400] ${titleStyle}`}
          >
            {title}
          </h4>
          <div
            className={`w-full h-[54px] bg-[#fff] rounded-[6px] flex ${inputStyles} ${
              showDropDown ? "rounded-bl-[0px]" : ""
            }`}
          >
            <div
              onClick={handleToggleDropDown}
              className={`w-[110px] h-full border-r border-r-[#707070] flex justify-between items-center pl-[20px] pr-[16px] `}
            >
              {currency ? (
                <img
                  onClick={() => {}}
                  src={
                    currency === "EUR"
                      ? require("../../Assets/Icons/currency-eur.png")
                      : currency === "USD"
                      ? require("../../Assets/Icons/currency-usd.png")
                      : currency === "GBP"
                      ? require("../../Assets/Icons/currency-gbp.png")
                      : currency === "NGN"
                      ? require("../../Assets/Icons/currency-ng.png")
                      : require("../../Assets/Icons/currency-gbp.png")
                  }
                  alt="currency"
                  className="w-[23px] object-cover h-[23px] rounded-full"
                />
              ) : (
                <></>
              )}
              <FaChevronDown color="#192851" />
            </div>
            <div className="flex justify-between w-full items-center pr-[20px]  pl-[16px]">
              <input
                value={value}
                onChange={onChange}
                id={id}
                name={id}
                type={"number"}
                className={`flex  text-[14px] md:text-base text-[#000] w-[70%] sm:w-[80%] leading-5 font-[600] outline-none  placeholder:text-black-50 transition-all ease-in-out duration-100 `}
              />

              <h4 className="text-[14px] md:text-base text-[#000] leading-5 font-[600]">
                {currency}
              </h4>
            </div>
          </div>
        </div>

        {showDropDown && (
          <div
            className={` ${
              showDropDown
                ? `flex absolute z-[3]  min-h-6 flex-col  transition-all ease-in-out duration-100  overflow-hidden  rounded-b-[6px] bg-[#FFF] ${position}`
                : "hidden w-[0px]  transition-all ease-in-out duration-100 "
            } ${dropDownStyles}`}
          >
            <div className=" flex flex-col overflow-y-auto max-h-[32rem]">
              {items
                ?.filter((i) => i !== currency)
                ?.map((curr, index) => (
                  <div
                    onClick={() => {
                      setSelected(curr);
                      onChangeCurrency(curr);
                      setDropDownState(false);
                    }}
                    key={index}
                    className={` h-[54px] hover:bg-[#E2E1E1]  transition-all ease-in-out duration-100  cursor-pointer flex justify-between items-center px-5`}
                  >
                    <img
                      src={
                        curr === "EUR"
                          ? require("../../Assets/Icons/currency-eur.png")
                          : curr === "USD"
                          ? require("../../Assets/Icons/currency-usd.png")
                          : curr === "GBP"
                          ? require("../../Assets/Icons/currency-gbp.png")
                          : curr === "NGN"
                          ? require("../../Assets/Icons/currency-ng.png")
                          : require("../../Assets/Icons/currency-gbp.png")
                      }
                      alt="currency"
                      className="w-[23px] object-cover h-[23px] rounded-full"
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CurrencyComponent;
