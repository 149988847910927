import { createAsyncThunk } from "@reduxjs/toolkit";
import { TRANSFER_RATES } from "../../../config/apis";
import { Client } from "../../../config/client";
import { toast } from "react-toastify";

export const getTransferRates = createAsyncThunk(
  "getTransferRates/get",
  async () => {
    try {
      const { data } = await Client({
        method: "GET",
        path: TRANSFER_RATES,
      });
      return data?.data;
    } catch (error) {
      // toast.error(error?.response?.data?.message || "Something went wrong");
      throw error;
    }
  }
);
