import { createAsyncThunk } from "@reduxjs/toolkit";
import { BENEFICIARIES } from "../../../config/apis";
import { Client } from "../../../config/client";
import { toast } from "react-toastify";

export const getBeneficiary = createAsyncThunk(
  "beneficiary/get",
  async (id) => {
    try {
      const {
        data: { user },
      } = await Client({
        method: "GET",
        path: `${BENEFICIARIES}${id}`,
      });
      toast.success("--");
      return user;
    } catch (error) {
      // toast.error(error?.response?.data?.message || "Something went wrong");
      throw error;
    }
  }
);
