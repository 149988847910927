import React, { useEffect, useState } from "react";
import PaymentCards from "../ListItems/PaymentCards";
import { useNavigate } from "react-router-dom";
import { getPaymentMethods } from "../../store/actions/payments/getPaymentMethods";
import { useDispatch, useSelector } from "react-redux";

const PaymentMethod = () => {
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate();
  const opts = [
    { cardNumber: "**** **** **** 2433", type: "MasterCard" },
    { cardNumber: "**** **** **** 3476", type: "AMEX" },
    { cardNumber: "**** **** **** 5478", type: "VISA" },
  ];

  const dispatch = useDispatch();

  const { pending, paymentMethods } = useSelector((state) => state?.user);
  useEffect(() => {
    dispatch(getPaymentMethods());
  }, []);
  return (
    <div className="w-full h-full py-8   mt-[75px] bg-[rgba(237,242,255,.5)] font-mons">
      <div className="container w-full  flex flex-col justify-center items-center mx-auto lg:min-h-[422px] ">
        <div className="w-full px-3 flex flex-col items-center ">
          <h3 className="text-[16px] mb-2 md:mb-3 md:text-[20px] text-[#000] font-[600] w-[95%] sm:w-[80%] md:w-[626.77px]">
            Payment Method
          </h3>
          {paymentMethods?.map((opt, index) => (
            <PaymentCards
              index={index}
              selected={selected}
              setSelected={setSelected}
              opt={opt}
              key={index}
            />
          ))}

          <h3
            onClick={() => {
              navigate("/settings/paymentmethod/addcard");
            }}
            className="text-[14px] cursor-pointer underline leading-[21.94px] text-center mt-3 md:text-[18px] text-[#395AB5] font-[600] w-full"
          >
            + Add payment method
          </h3>

          <div
            onClick={() => {}}
            className="flex justify-center cursor-pointer mx-auto mt-6 items-center group hover:bg-transparent transition-all duration-500 ease-in-out border-[.5px] border-[#395AB5]  bg-[#FFF]  w-[260px] rounded-[14px] h-[56px] "
          >
            <h4 className="text-base text-[#000]  font-[400]">
              Apply promo code
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
