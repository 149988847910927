import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";

const data = [
  {
    name: "Jan",
    income: 4000,
    expenditure: 2400,
  },
  {
    name: "Feb",
    income: 3000,
    expenditure: 1398,
  },
  {
    name: "Mar",
    income: 2000,
    expenditure: 9800,
  },
  {
    name: "Apr",
    income: 2780,
    expenditure: 3908,
  },
  {
    name: "May",
    income: 1890,
    expenditure: 4800,
  },
  {
    name: "Jun",
    income: 2390,
    expenditure: 3800,
  },
  {
    name: "Aug",
    income: 3490,
    expenditure: 4300,
  },
  {
    name: "Sep",
    income: 3490,
    expenditure: 4300,
  },
  {
    name: "Oct",
    income: 3490,
    expenditure: 4300,
  },
  {
    name: "Nov",
    income: 3490,
    expenditure: 4300,
  },
  {
    name: "Dec",
    income: 3490,
    expenditure: 4300,
  },
];

// const IncomeAndExpenditure = ({ data }) => {
const IncomeAndExpenditure = ({}) => {
  const getBarColor = (value) => {
    if (value >= 0 && value <= 6) {
      return "#BECCF1"; // Black
    } else if (value >= 7 && value <= 8) {
      return "#192851"; // Gray
    } else if (value >= 9 && value <= 10) {
      //   return "#3A4045"; // Blue
    }
  };

  return (
    <ResponsiveContainer
      height={"90%"}
      width={"100%"}
      style={{ outline: "none", border: "none" }}
    >
      <BarChart width={"100%"} height={"100%"} data={data}>
        <CartesianGrid vertical={false} />
        <XAxis dataKey="name" tickLine={false} />
        <YAxis tickLine={false} axisLine={false} />
        <Tooltip />

        <Bar dataKey="income" fill="#192851" barSize={12} />
        <Bar dataKey="expenditure" fill="#BECCF1" barSize={12} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default IncomeAndExpenditure;
