import { createSlice } from "@reduxjs/toolkit";
import { getBanks } from "../actions/banks/getBanks";

export const banksSlice = createSlice({
  name: "banks",
  initialState: {
    error: null,
    pending: false,
    success: false,
    data: [],
    dataDetails: {},
  },

  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBanks.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(getBanks.fulfilled, (state, action) => {
      state.data = action.payload;
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(getBanks.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
  },
});

export const {} = banksSlice.actions;
export default banksSlice.reducer;
