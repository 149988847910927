import { createAsyncThunk } from "@reduxjs/toolkit";
import { DIRECT_TRANSFER } from "../../../config/apis";
import { Client } from "../../../config/client";
import { toast } from "react-toastify";

export const transfer = createAsyncThunk(
  "transfer/post",
  async ({ payload, currency, cb, failed }) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: `${DIRECT_TRANSFER}${"USD"}`,
        data: payload,
      });
      cb();

      // return user;
    } catch (error) {
      failed();
      toast.error(error?.response?.data?.message || "Something went wrong");
      throw error;
    }
  }
);
