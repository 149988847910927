import React from "react";
import ExchangeRate from "./ExchangeRate";

const TransferTop = ({ form, oldState }) => {
  return (
    <div className="w-full    bg-[rgba(25,40,81,1)]  pt-[54px]">
      <div className="relative  bg-[url('./Assets/Pattern/background-pattern3.png')]   bg-right bg-cover w-full flex justify-center items-center font-mons ">
        <div className="container w-full  flex flex-col justify-center items-center mx-auto lg:min-h-[426px] ">
          <ExchangeRate
            oldState={oldState}
            form={form}
            type={2}
            styles={
              "bg-transparent max-w-full !mb-0 min-w-[80%] md:min-w-[655px] !px-[20px] !mt-0"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default TransferTop;
