import { createAsyncThunk } from "@reduxjs/toolkit";
import { LOGIN } from "../../../config/apis";
import { Client } from "../../../config/client";
import { processSuccessMessage } from "../../../utils/processSuccessMessage";

import { proccessErrorMessage } from "../../../utils/proccessErrorMessage";
import { toast } from "react-toastify";
import { Cookies } from "react-cookie";

export const login = createAsyncThunk("auth/login", async (payload) => {
  const { navigate, data, setSubmitting } = payload;
  try {
    const { data: _data } = await Client({
      method: "POST",
      path: LOGIN,
      data: data,
    });
    processSuccessMessage(_data?.message);

    const date = new Date();
    const nextDate = date.setMinutes(date.getMinutes() + 2);
    const cookies = new Cookies();

    cookies.set("accessToken", _data.token, {
      // expires: new Date(nextDate),
    });
    navigate("/");
    setSubmitting(false);
    return _data.user;
  } catch (error) {
    toast.error("Login failed: Check your credentials.");
    proccessErrorMessage(error);
    setSubmitting(false);

    throw error;
  }
});
