import { createAsyncThunk } from "@reduxjs/toolkit";
import { PAYMENT_METHOD, PAYMENTS } from "../../../config/apis";
import { Client } from "../../../config/client";
import { toast } from "react-toastify";

export const createPaymentMethod = createAsyncThunk(
  "paymentMethod/post",
  async ({ payload, cb, failed }) => {
    try {
      // const { data } = await Client({
      //   method: "POST",
      //   path: PAYMENT_METHOD,
      //   data: payload,
      // });
      cb();
      return payload?.token;
      // return data?.data;
    } catch (error) {
      failed();
      toast.error(error?.response?.data?.message || "Something went wrong");
      throw error;
    }
  }
);
