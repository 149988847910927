import React from "react";

const Reviews = () => {
  const reviews = [
    {
      ratings: 5,
      name: "Moses Ugandiwu",
      details:
        "The first time i heard of this service was through a friend, and then i decided to give it a trial. Was i impressed? oh definitely yes. ",
    },
    {
      ratings: 3,
      name: "Bliss Kolanga",
      details:
        "I've been using this money transfer service for a while, and it's never let me down. The process is straightforward, and I appreciate the added security measures they have in place. ",
    },
    {
      ratings: 4,
      name: "Donald Nolan",
      details:
        "Five stars all the way! This money transfer service exceeded my expectations. The speed of transactions is impressive. ",
    },
  ];

  return (
    <div className="relative bg-[url('./Assets/Pattern/background-pattern2.png')]  w-full  font-mons ">
      <div className="w-full h-full pt-4 pb-4 md:pb-16  bg-[rgba(57,90,181,0.92)]">
        <div className="container  flex flex-col justify-center items-center mx-auto lg:min-h-[614px] ">
          <div className="mx-auto  flex justify-center px-4 mb-6">
            <h1 className="font-[700] text-[200px]  h-[106px] leading-none text-[#FFF] ">
              ”
            </h1>
            <div className="max-w-[300px] mt-2 flex">
              <h3 className="text-[#FFF] text-[37px] font-[700] leading-[45.1px] text-center">
                What people are saying?
              </h3>
            </div>
          </div>

          <div className="flex  justify-between gap-5 items-center ">
            <div className="flex flex-col lg:flex-row justify-between gap-8 mt-6 flex-wrap flex-1">
              {reviews?.map((review, index) => {
                return (
                  <div
                    key={index}
                    className="w-[288px] p-3 lg:py-6 lg:px-4 h-[292px] rounded-[40px] bg-[#FFF]"
                  >
                    <div className="flex gap-1 mx-auto mt-1 mb-3 justify-center items-center">
                      {[1, 2, 3, 4, 5].map((item) => {
                        return item <= review.ratings ? (
                          <img
                            key={index}
                            className="w-[27px] h-[27px] object-contain"
                            src={require("../../Assets/Icons/favourite.png")}
                            alt="ratings"
                          />
                        ) : (
                          <></>
                        );
                      })}
                    </div>
                    <h6 className="color-[#000] mt-1 leading-[25.5px] text-[15px] font-[400] text-center">
                      {review.details}
                    </h6>
                    <h3 className="text-[#1C2C57] text-[15px] mt-3 font-[500] text-center">
                      -{review?.name}
                    </h3>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
