import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Recipient = ({ data, savedBeneficiary, saveBeneficiary }) => {
  const {
    accountNumber,
    bankName,
    recipient,
    reference,
    amount,
    beneficiary,
    currency,
    recipientCurrency,
    swiftCode,
  } = data;
  const navigate = useNavigate();

  return (
    <div className="w-full h-full    my-7 md:my-10  font-mons">
      <div className="container w-full bg-[rgba(237,242,255,.5)] max-w-[90%] px-9 py-6 flex flex-col justify-center items-center mx-auto lg:min-h-[261px] rounded-[22px] ">
        <div className="w-full max-w-[627px] flex flex-col ">
          <div className="flex  w-full mb-1 justify-between items-center ">
            <h4 className="font-[600] text-[18px] md:text-[20px] text-[#000]">
              Recipient
            </h4>
            <h4
              onClick={() => {
                navigate("/transfer", { state: data });
              }}
              className="font-[600] text-[18px] md:text-[20px] text-[#395AB5] cursor-pointer "
            >
              Edit
            </h4>
          </div>
          <h5 className="font-[400] mb-1 text-[16px] md:text-[18px] text-[#000] ">
            {recipient}
          </h5>
          <div className="flex w-max  gap-2 justify-between items-center ">
            <img
              className="w-[38.39px] h-[36.56px] object-contain "
              src={require("../../Assets/Images/gtb.png")}
              alt="gtb"
            />
            <div>
              <h6 className="font-[400] text-[14px] text-[#000] ">
                {accountNumber}
              </h6>
              <h6 className="font-[4{00] text-[14px] text-[#000] ">
                ({bankName?.label})
              </h6>
            </div>
          </div>
          <div
            onClick={() => {
              saveBeneficiary((prev) => !prev);
            }}
            className="flex w-max my-2 gap-2  cursor-pointer justify-between items-center "
          >
            <div
              className={`w-[12px] h-[12px] ${
                savedBeneficiary
                  ? "bg-[rgba(24,200,20,1)]"
                  : "bg-[#FFF] border-[0.2px] border-[#DEDDDD]"
              } `}
            ></div>
            <h5 className="font-[400]  text-[14px] md:text-[18px] text-[#395AB5] ">
              Save as beneficiary
            </h5>
          </div>
          <h5 className="font-[500] mt-3 text-[14px] md:text-[18px] text-[#000000] ">
            Reference:{" "}
            <span className="font-[400] text-[#000000] text-[14px] md:text-[18px]">
              {reference}
            </span>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Recipient;
