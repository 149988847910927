import React, { useEffect } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import SideBar from "../Components/Bars/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoMdArrowUp } from "react-icons/io";
import Loader from "../Components/Utils/Loader";
import { getTransactions } from "../store/actions/transactions/getTransactions";
import { currencySymbolMap } from "../utils/data";
import { timeformatter } from "../utils/dateformatter";

const TransactionHistory = () => {
  const { pending, data } = useSelector((state) => state.transactions);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTransactions());
  }, []);

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Transactions"}
          description={""}
          name={"Imperial Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <SideBar />
          <div className="flex-1 mb-14 max-w-[100vw] overflow-x-auto pt-8 pb-6 px-5 md:px-9 bg-[#F1F1F1]">
            <div className="flex items-center justify-between">
              <h4 className="font-[600] text-lg mb-1">Transaction History</h4>
            </div>
            {pending && data?.length < 1 ? (
              <Loader />
            ) : (
              <div className="mt-5 ">
                <div className="flex w-[1043px] mb-6  flex-col  justify-between">
                  <div className="h-[75%] rounded-[20px] border-[#D1D1D1] border-[0.8px] p-5 bg-[#FFF]">
                    <div className="flex border-b-[#D1D1D1]  border-b-[0.5px] py-3">
                      <h4 className="font-[500] text-base text-[#707070] w-[80px] ">
                        No.
                      </h4>
                      <h4 className="font-[500] text-base text-[#707070] w-[250px]">
                        Recipient Name
                      </h4>
                      <h4 className="font-[500] text-base text-[#707070] w-[200px] ">
                        Transaction Ref
                      </h4>
                      <h4 className="font-[500] text-base text-[#707070] w-[140px] ">
                        Amount
                      </h4>
                      <h4 className="font-[500] text-base text-[#707070] w-[130px] ">
                        Type
                      </h4>
                      <h4 className="font-[500] text-base text-[#707070] w-[200px] ">
                        Payment Date
                      </h4>
                    </div>
                    <div className="overflow-y-auto h-[80%] ">
                      {data?.map((item) => (
                        <div
                          key={item?.id}
                          onClick={() => {
                            navigate(`/transactions/${item?.id}`);
                          }}
                          className="flex border-b-[#D1D1D1] cursor-pointer items-center border-b-[0.5px] py-3"
                        >
                          <h4 className="font-[500] text-base text-[#000000] w-[80px] ">
                            {item?.id}
                          </h4>
                          <h4 className="font-[500] text-base  text-[#000000] w-[250px] ">
                            {item?.recipient}
                          </h4>
                          <h4 className="font-[500] text-base text-[#000000] w-[200px]">
                            {item?.transaction_ref}
                          </h4>
                          <h4 className="font-[500] text-base text-[#000000] w-[140px]">
                            {currencySymbolMap[item?.currency]}
                            {item?.amountSent}
                          </h4>
                          <div className="font-[500] cursor-pointer text-base text-[#000000] flex gap-2 items-center w-[130px] ">
                            <h4 className=" font-[500] text-base text-[#E00202] ">
                              {item?.transType}
                            </h4>
                            <div className="w-[14px] h-[15px] rounded-[2px] flex justify-center items-center bg-[#E00202] ">
                              <IoMdArrowUp size={"14px"} color="#FFF" />
                            </div>
                          </div>

                          <div>
                            <h4 className="cursor-pointer font-[500] text-base text-[#000000] w-[200px] ">
                              {timeformatter(item?.transaction_date, "formal")}
                            </h4>
                            <h4 className="cursor-pointer font-[500] text-[12px] text-[#707070] w-[200px] ">
                              {timeformatter(
                                item?.transaction_date,
                                "time_only"
                              )}
                            </h4>
                          </div>
                        </div>
                      ))}
                      {/* <div
                        onClick={() => {
                          navigate(`/transactions/${2}`);
                        }}
                        className="flex border-b-[#D1D1D1] cursor-pointer items-center border-b-[0.5px] py-3"
                      >
                        <h4 className="font-[500] text-base text-[#000000] w-[80px] ">
                          2.
                        </h4>
                        <h4 className="font-[500] text-base  text-[#000000] w-[250px] ">
                          Jones Bradford
                        </h4>
                        <h4 className="font-[500] text-base text-[#000000] w-[200px]">
                          IMP-4377878287768
                        </h4>
                        <h4 className="font-[500] text-base text-[#000000] w-[140px]">
                          £800
                        </h4>
                        <div className="font-[500] cursor-pointer text-base text-[#000000] flex gap-2 items-center w-[130px] ">
                          <h4 className=" font-[500] text-base text-[#E00202] ">
                            Debit
                          </h4>
                          <div className="w-[14px] h-[15px] rounded-[2px] flex justify-center items-center bg-[#E00202] ">
                            <IoMdArrowUp size={"14px"} color="#FFF" />
                          </div>
                        </div>
                        <div>
                          <h4 className="cursor-pointer font-[500] text-base text-[#000000] w-[200px] ">
                            May 22, 2024
                          </h4>
                          <h4 className="cursor-pointer font-[500] text-[12px] text-[#707070] w-[200px] ">
                            14:57 pm
                          </h4>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <Button
                  bg={"#192851"}
                  title={"Save"}
                  textStyles="font-[600] text-[18px]"
                  mainClasses="h-[56px] mx-auto "
                  // onClick={form.handleSubmit}
                  onClick={() => {
                    setShowSuccess(true);
                    // navigate("/payment");
                  }}
                  loading={form.isSubmitting}
                  // disabled={!form.dirty || !form.isValid || form.isSubmitting}
                /> */}
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TransactionHistory;
