import React, { useEffect } from "react";
import Header from "../Components/Bars/Header";
import SEO from "../Components/Utils/Seo";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { formHandler } from "../utils/formhandler";
import TextInput from "../Components/Inputs/TextInput";
import Button from "../Components/Inputs/Button";
import { profileSchema } from "../utils/validationSchemas";
import SelectField from "../Components/Inputs/SelectField";
import { countries } from "../utils/data";
import DatePicker from "../Components/Inputs/DatePicker";
import ImagePicker from "../Components/Inputs/ImagePicker";
import { completeProfile } from "../store/actions/profile/completeProfile";
import { getProfile } from "../store/actions/profile/getProfile";

const EditProfile = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);
  const { dataDetails } = useSelector((state) => state.profile);

  const dispatch = useDispatch();
  const form = useFormik({
    initialValues: {
      fullName: "",
      phone: "",
      email: "",
      dateOfBirth: null,
      gender: null,
      profilePicture: null,
      idNumber: "",
      country: null,
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: profileSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const data = {
        ...values,
        // recipientBankName: values?.recipientBankName?.value,
      };

      const formData = new FormData();
      formData.append("fullName", form.values.fullName);
      formData.append("phone", form.values.phone);
      formData.append("email", form.values.email);
      formData.append("idNumber", form.values.idNumber);
      formData.append("dateOfBirth", form.values.dateOfBirth);
      formData.append("gender", form.values.gender?.value);
      formData.append("country", form.values.country?.value);
      formData.append("profilePicture", form.values.profilePicture);

      dispatch(
        completeProfile({
          cb: () => {
            // resetForm();
            setSubmitting(false);
            // navigate("/payments");
            // setShowSuccess(true);
          },
          payload: formData,
          failed: () => {
            setSubmitting(false);
          },
        })
      );
    },
  });

  useEffect(() => {
    const {
      country,
      dateOfBirth,
      email,
      fullName,
      gender,
      idNumber,
      phone,
      profilePicture,
    } = dataDetails;

    form.setValues({
      fullName,
      phone,
      email,
      dateOfBirth,
      gender: {
        label: gender,
        item: gender,
      },
      profilePicture,
      idNumber,
      country: {
        label: country,
        item: country,
      },
    });
  }, [dataDetails]);

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Profile"}
          description={""}
          name={"Imperial Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <div className="flex-1  max-w-[100vw] overflow-x-auto pt-4 pb-6 px-5 md:px-9 bg-[#F1F1F1]">
            <h4
              onClick={() => {
                navigate(-1);
              }}
              className="cursor-pointer text-[21px] sm:text-[25px] font-[600] w-[646px] max-w-[100%] mt-6 mb-3 mx-auto text-[#000]"
            >
              Settings / Edit Profile
            </h4>
            <div className="w-[646px] max-w-[100%] px-5 py-5 sm:px-8 mx-auto bg-[#FFFFFF]">
              <div className="w-full items-center flex flex-col">
                <ImagePicker
                  id="profilePicture"
                  {...formHandler("profilePicture", form)}
                  type={"text"}
                  form={form}
                  mainClasses={"!w-full sm:max-w-[371px]"}
                />
                <div className="flex w-full flex-col mb-6 items-center gap-4">
                  <TextInput
                    title={"Name"}
                    id={"fullName"}
                    {...formHandler("fullName", form)}
                    type={"text"}
                    placeholder={user?.fullName}
                    mainClasses={"!w-full sm:max-w-[371px]"}
                  />
                  <TextInput
                    title={"Phone Number"}
                    id="phone"
                    {...formHandler("phone", form)}
                    type={"number"}
                    placeholder={"+44 1023 899021 "}
                    mainClasses={"!w-full sm:max-w-[371px]"}
                  />
                  <TextInput
                    title={"ID Number"}
                    id="idNumber"
                    {...formHandler("idNumber", form)}
                    type={"number"}
                    placeholder={"+44 1023 899021 "}
                    mainClasses={"!w-full sm:max-w-[371px]"}
                  />
                  <TextInput
                    title={"Email Address"}
                    {...formHandler("email", form)}
                    type={"email"}
                    placeholder={user?.email}
                    mainClasses={"!w-full sm:max-w-[371px]"}
                  />
                  <DatePicker
                    title={"Date of Birth"}
                    id="dateOfBirth"
                    {...formHandler("dateOfBirth", form)}
                    type={"text"}
                    form={form}
                    placeholder={"DD/MM/YY"}
                    mainClasses={"!w-full sm:max-w-[371px]"}
                  />
                  <div className="w-full md:w-[371px]">
                    <SelectField
                      title="Gender"
                      id="gender"
                      form={form}
                      options={[
                        {
                          label: "Male",
                          value: "Male",
                        },
                        {
                          label: "Female",
                          value: "Female",
                        },
                      ]}
                      sx={{ width: "100%", height: "50px" }}
                    />
                  </div>

                  <div className="w-full md:w-[371px]">
                    <SelectField
                      title="Country"
                      id="country"
                      form={form}
                      options={countries?.map((i) => ({
                        label: i,
                        value: i,
                      }))}
                      sx={{ width: "100%", height: "50px" }}
                    />
                  </div>
                </div>

                <Button
                  bg={"#192851"}
                  title={"Update Profile"}
                  textStyles="font-[600]  text-[18px]"
                  mainClasses="h-[56px]  "
                  onClick={form.handleSubmit}
                  loading={form.isSubmitting}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
