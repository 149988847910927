import React from "react";
import { FaGoogle } from "react-icons/fa";
import { IoLogoFacebook } from "react-icons/io5";
import Loader from "../Utils/Loader";

const Button = ({
  mainClasses,
  title,

  height,
  bg,
  color,
  leftIcon,
  rightIcon,
  textStyles,
  onClick,
  loading,
  disabled,
}) => {
  return (
    <div
      onClick={disabled ? null : onClick}
      className={`flex justify-center   border-[1px]  my-3 gap-2 items-center px-3  w-[243px] h-[48px] rounded-[12px] transition-all ease-in-out duration-300  ${
        disabled
          ? "cursor-not-allowed"
          : `hover:bg-transparent cursor-pointer group hover:border-[${bg}]`
      } ${bg ? `bg-[${bg}]` : ""} ${mainClasses}`}
      style={{
        height,
        backgroundColor: leftIcon || rightIcon || disabled ? bg : null,
        opacity: disabled ? 0.4 : null,
      }}
    >
      {loading ? (
        <Loader size={"small"} />
      ) : (
        <>
          {leftIcon === "facebook" ? (
            <IoLogoFacebook size={"21px"} color="#FFFFFF" />
          ) : leftIcon === "google" ? (
            <FaGoogle size={"20px"} color="#FFFFFF" />
          ) : (
            <></>
          )}

          <h4
            className={`text-[#FFF] group-hover:text-[${bg}] font-[500] text-[14px] transition-all ease-in-out duration-300 ${textStyles}`}
            style={{
              color,
            }}
          >
            {title}
          </h4>
        </>
      )}
    </div>
  );
};

export default Button;
