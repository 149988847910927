import React from "react";
import { RiSearch2Line } from "react-icons/ri";

const Search = ({
  value,
  onChange,
  title,
  placeholder = "Search",
  disabled,
  mainClasses,
  inputClasses,
  defaultValue,
  id,
  type,
}) => {
  return (
    <div
      className={`font-mons w-[367px] flex gap-1 h-[56px] px-4 bg-[#fff] rounded-[14px] items-center ${mainClasses}`}
    >
      <RiSearch2Line color="#666666" size={"20px"} />
      <input
        value={value}
        onChange={onChange}
        id={"searh"}
        name={"searh"}
        type={"text"}
        placeholder={placeholder}
        className={`flex w-full  text-[14px] outline-none  placeholder:text-black-50 transition-all ease-in-out duration-100 h-[44px] px-1 md:px-4 ${inputClasses}`}

        // onClick={(e) => e.stopPropagation()}
      />
    </div>
  );
};

export default Search;
