import { combineReducers } from "@reduxjs/toolkit";
import ui from "./reducer/uiSlice";
import user from "./reducer/userSlice";
import profile from "./reducer/profileSlice";
import beneficiaries from "./reducer/beneficiariesSlice";
import transactions from "./reducer/transactionSlice";
import banks from "./reducer/banksSlice";
import tracking from "./reducer/trackingSlice";
import dashboard from "./reducer/dashboardSlice";

export const rootReducer = combineReducers({
  ui,
  user,
  profile,
  beneficiaries,
  transactions,
  banks,
  dashboard,
  tracking,
});
