import { createSlice } from "@reduxjs/toolkit";
import { getBeneficiaries } from "../actions/beneficiaries/getBeneficiaries";
import { getBeneficiary } from "../actions/beneficiaries/getBeneficiary";
import { deleteBeneficiary } from "../actions/beneficiaries/deleteBeneficiary";
import { editBeneficiary } from "../actions/beneficiaries/editBeneficiary";
import { createBeneficiary } from "../actions/beneficiaries/createBeneficiary";
import { login } from "../actions/onboarding/login";
import { register } from "../actions/onboarding/register";
import { loginWithGoogle } from "../actions/onboarding/loginWithGoogle";
import { loginWithFacebook } from "../actions/onboarding/loginWithFacebook";

export const beneficiariesSlice = createSlice({
  name: "beneficiaries",
  initialState: {
    error: null,
    pending: false,
    success: false,
    data: [],
    dataDetails: {},
  },

  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.data = [];
      state.dataDetails = {};
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.data = [];
      state.dataDetails = {};
    });
    builder.addCase(loginWithGoogle.fulfilled, (state, action) => {
      state.data = [];
      state.dataDetails = {};
    });
    builder.addCase(loginWithFacebook.fulfilled, (state, action) => {
      state.data = [];
      state.dataDetails = {};
    });
    builder.addCase(getBeneficiaries.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(getBeneficiaries.fulfilled, (state, action) => {
      state.data = action.payload;
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(getBeneficiaries.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
    builder.addCase(getBeneficiary.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(getBeneficiary.fulfilled, (state, action) => {
      state.dataDetails = action.payload;
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(getBeneficiary.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
    builder.addCase(deleteBeneficiary.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(deleteBeneficiary.fulfilled, (state, action) => {
      state.data = state.data?.filter((ben) => ben?.id != action.payload);
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(deleteBeneficiary.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
    builder.addCase(editBeneficiary.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(editBeneficiary.fulfilled, (state, action) => {
      // state.data = state.data;
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(editBeneficiary.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
    builder.addCase(createBeneficiary.pending, (state, action) => {
      state.pending = true;
      state.error = null;
    });
    builder.addCase(createBeneficiary.fulfilled, (state, action) => {
      // state.data = state.data;
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(createBeneficiary.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
    });
  },
});

export const {} = beneficiariesSlice.actions;
export default beneficiariesSlice.reducer;
