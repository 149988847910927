import Axios from "axios";
import { proccessErrorMessage } from "../utils/proccessErrorMessage";

import { baseURL } from "./apis";
import { Cookies } from "react-cookie";

export const Client = async (params) => {
  const cookies = new Cookies();

  const token = cookies.get("accessToken");

  const {
    path,
    fullPath,
    method,
    data,
    contentType = "application/json",
  } = params;
  const headers = {
    ...(token && {
      Authorization: `Bearer ${token}`,
    }),

    Accept: "application/json",
    "Content-Type": `${contentType}`,
  };

  let url = fullPath || `${baseURL}${path}`;
  const requestBody = {
    method,
    url,
    headers,
    data: !(contentType === "multipart/form-data")
      ? JSON.stringify(data)
      : data,
    responseType: "json",
  };
  try {
    const response = await Axios(requestBody);
    const result = response;
    return result;
  } catch (error) {
    if (
      error?.response?.data?.message === "Token is expired." ||
      error?.response?.data?.message ===
        "No authentication token, access denied."
    ) {
      window.location = `/sign-in`;

      localStorage.clear();
      cookies.remove("accessToken");
    }
    if (params?.processError === false) {
      throw error;
    } else {
      proccessErrorMessage(error);
      throw error;
    }
  }
};
