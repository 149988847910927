import { createAsyncThunk } from "@reduxjs/toolkit";
import { Client } from "../../../config/client";
import { proccessErrorMessage } from "../../../utils/proccessErrorMessage";
import { FACEBOOK_AUTH } from "../../../config/apis";
import { toast } from "react-toastify";
import { Cookies } from "react-cookie";

export const loginWithFacebook = createAsyncThunk(
  "auth/facebookLogin",
  async (payload) => {
    const { token, setSubmitting, navigate } = payload;
    try {
      const { data: _data } = await Client({
        method: "POST",
        path: FACEBOOK_AUTH + token,
      });

      toast.success("Login Successfull");

      const date = new Date();
      const nextDate = date.setMinutes(date.getMinutes() + 2);
      const cookies = new Cookies();

      cookies.set("accessToken", _data.access_token, {
        // expires: new Date(nextDate),
      });
      navigate("/");
      setSubmitting(false);
      return _data.user;
    } catch (error) {
      proccessErrorMessage(error);
      setSubmitting(false);

      throw error;
    }
  }
);
