import { createAsyncThunk } from "@reduxjs/toolkit";
import { REGISTER } from "../../../config/apis";
import { Client } from "../../../config/client";
import { processSuccessMessage } from "../../../utils/processSuccessMessage";
import { proccessErrorMessage } from "../../../utils/proccessErrorMessage";
import { Cookies } from "react-cookie";

export const register = createAsyncThunk("auth/register", async (payload) => {
  const { navigate, data, setSubmitting } = payload;

  try {
    const { data: _data } = await Client({
      method: "POST",
      path: REGISTER,
      data: data,
    });

    const date = new Date();
    const nextDate = date.setMinutes(date.getMinutes() + 2);
    const cookies = new Cookies();

    cookies.set("accessToken", _data?.accessToken, {
      // expires: new Date(nextDate),
    });

    processSuccessMessage(_data?.message);

    navigate("/verify", { state: { email: data.email } });
    setSubmitting(false);
    return _data.user;
  } catch (error) {
    proccessErrorMessage(error);

    setSubmitting(false);
    throw error;
  }
});
