import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { formHandler } from "../../utils/formhandler";
import TextInput from "../Inputs/TextInput";

import Button from "../Inputs/Button";
import { useDispatch, useSelector } from "react-redux";
import SelectField from "../Inputs/SelectField";
import { getBeneficiaries } from "../../store/actions/beneficiaries/getBeneficiaries";

const TransferBottom = ({ form }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: bankData } = useSelector((state) => state?.banks);
  const { pending, data: beneficiaries } = useSelector(
    (state) => state?.beneficiaries
  );
  useEffect(() => {
    dispatch(getBeneficiaries());
  }, []);

  return (
    <div className="w-full h-full pt-4 pb-4 md:pb-2  bg-[rgba(241,241,241,0.5)] font-mons">
      <div className="container  flex flex-col justify-center items-center mx-auto lg:min-h-[565px] ">
        <div>
          <h4 className="text-[20px] mb-1 md:text-[27px] text-center text-[#000] font-[500]">
            Add Recipient details
          </h4>
          <h4 className="text-[12px] mb-6 md:mb-7 max-w-[273px] text-center  text-[#000] font-[300]">
            To avoid delays recipient name should match the name on their bank
            account
          </h4>
        </div>
        <div className="flex w-[80%] md:w-[799px] gap-3 flex-col mb-4 lg:flex-row items-center justify-between">
          <TextInput
            title={"Full Name"}
            {...formHandler("recipient", form)}
            type={"text"}
            placeholder={"e.g Williams Jones "}
            mainClasses={" md:w-[363.23px]"}
          />

          <TextInput
            title={"IBAN/Account Number"}
            {...formHandler("accountNumber", form)}
            type={"text"}
            // placeholder={"e.g Jones "}
            mainClasses={"md:w-[363.23px]"}
          />
        </div>
        <div className="flex w-[367px] gap-3 md:w-[799px] flex-col mb-4 lg:flex-row items-center justify-between">
          <div className="w-full md:w-[363.23px]">
            <SelectField
              title="Bank Name"
              id="bankName"
              // clearFields={[
              //   // "account_name",
              //   "account_numberUSD",
              //   "sort_code",
              //   "swift_code",
              //   "routing_number",
              //   "IBAN",
              //   "beneficiary_bank_name",
              //   "beneficiary_name",
              //   "SWIFT_BIC",
              //   "beneficiary_bank_address",
              //   "beneficiary_address",
              //   "beneficiary_country",
              // ]}
              form={form}
              options={bankData?.map((i) => ({
                ...i,
                label: i.name,
                value: i.name,
              }))}
              sx={{ width: "100%", height: "50px" }}
            />
          </div>

          <TextInput
            title={"BIC/Swift Code"}
            {...formHandler("swiftCode", form)}
            type={"text"}
            placeholder={"e.g First Bank Plc"}
            mainClasses={"  md:w-[363.23px]"}
          />
        </div>
        <div className="flex w-[367px] gap-3 md:w-[799px] mb-6 items-center flex-col lg:flex-row justify-between">
          <div className="w-full md:w-[363.23px]">
            <SelectField
              title="Select existing beneficiary"
              id="beneficiary"
              form={form}
              options={beneficiaries?.map((i) => ({
                ...i,
                label: i.fullName,
                value: i.fullName,
              }))}
              onChange={(e) => {
                const value = e.target.value;
                const {
                  accountNumber,
                  bankName,

                  fullName,
                  swiftCode,
                } = value;

                form.setValues({
                  ...form.values,
                  beneficiary: value,
                  accountNumber,
                  bankName: { id: bankName, label: bankName },
                  recipient: fullName,
                  swiftCode,
                });
              }}
              sx={{ width: "100%", height: "50px" }}
            />
          </div>

          <TextInput
            title={"Reference"}
            {...formHandler("reference", form)}
            type={"text"}
            placeholder={"e.g For groceries"}
            mainClasses={"  md:w-[363.23px]"}
          />
        </div>
        <Button
          bg={"#192851"}
          title={"Continue"}
          textStyles="font-[600] text-[18px]"
          mainClasses="h-[56px]"
          onClick={form.handleSubmit}
        />
      </div>
    </div>
  );
};

export default TransferBottom;
