import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import AboutUs from "./Pages/AboutUs";
import Services from "./Pages/Services";
import OTP from "./Pages/OTP";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import FinancialNews from "./Pages/FinancialNews";
import Dashboard from "./Pages/Dashboard";
import Transfer from "./Pages/Transfer";
import Payment from "./Pages/Payment";
import Beneficiaries from "./Pages/Beneficiaries";
import AddBeneficiaries from "./Pages/AddBeneficiaries";
import FundWallet from "./Pages/FundWallet";
import TrackPayments from "./Pages/TrackPayments";
import TrackingDetails from "./Pages/TrackingDetails";
import TransactionHistory from "./Pages/TransactionHistory";
import TransactionReceipt from "./Pages/TransactionReceipt";
import RecurringPayments from "./Pages/RecurringPayments";
import Settings from "./Pages/Settings";
import EditProfile from "./Pages/EditProfile";
import ChangePassword from "./Pages/ChangePassword";
import PaymentMethod from "./Pages/PaymentMethod";
import AddCard from "./Pages/AddCard";
import KYC from "./Pages/KYC";
import DriversLicense from "./Pages/DriversLicense";
import Passport from "./Pages/Passport";
import BiometricPassport from "./Pages/BiometricPassport";
import Refer from "./Pages/Refer";
import Help from "./Pages/Help";
import Terms from "./Pages/Terms";
import ExchangeRate from "./Pages/ExchangeRate";
import Blog from "./Pages/Blog";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
console.log(process.env.REACT_APP_P_KEY, "process.env.P_KEY");
console.log(process.env.REACT_APP_GOOGLE_CLIENT_ID, "process.env.P_KEY");
const stripePromise = loadStripe(process.env.REACT_APP_P_KEY);
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "about-us",
    element: <AboutUs />,
  },
  {
    path: "sign-in",
    element: <Login />,
  },
  {
    path: "sign-up",
    element: <Signup />,
  },
  {
    path: "verify",
    element: <OTP />,
  },
  {
    path: "services",
    element: <Services />,
  },
  {
    path: "/help",
    element: <Help />,
  },
  {
    path: "/privacy-policy",
    element: <Terms />,
  },
  {
    path: "/exchange-rate",
    element: <ExchangeRate />,
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);
const protectedRouter = createBrowserRouter([
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "sign-in",
    element: <Login />,
  },
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/beneficiaries",
    element: <Beneficiaries />,
  },
  {
    path: "/payments",
    element: <TrackPayments />,
  },
  {
    path: "/payments-tracking/:id",
    element: <TrackingDetails />,
  },
  {
    path: "/transactions",
    element: <TransactionHistory />,
  },
  {
    path: "/transactions/:id",
    element: <TransactionReceipt />,
  },
  {
    path: "recurring-payments",
    element: <RecurringPayments />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/exchange-rate",
    element: <ExchangeRate />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/refer",
    element: <Refer />,
  },
  {
    path: "/help",
    element: <Help />,
  },
  {
    path: "/privacy-policy",
    element: <Terms />,
  },
  {
    path: "/settings/editprofile",
    element: <EditProfile />,
  },
  {
    path: "/settings/changepassword",
    element: <ChangePassword />,
  },
  {
    path: "/settings/paymentmethod",
    element: <PaymentMethod />,
  },
  {
    path: "/settings/paymentmethod/addcard",
    element: <AddCard />,
  },
  {
    path: "/settings/kyc",
    element: <KYC />,
  },
  {
    path: "/settings/kyc/driverslicense",
    element: <DriversLicense />,
  },
  {
    path: "/settings/kyc/passport",
    element: <Passport />,
  },
  {
    path: "/settings/kyc/biometricresidentialpassport",
    element: <BiometricPassport />,
  },
  {
    path: "/settings/kyc/biometricresidentialpassport",
    element: <Passport />,
  },
  {
    path: "/add-beneficiaries",
    element: <AddBeneficiaries />,
  },
  {
    path: "/fund-wallet",
    element: <FundWallet />,
  },
  {
    path: "/transfer",
    element: <Transfer />,
  },
  {
    path: "/transfer/confirmation",
    element: <Payment />,
  },
  {
    path: "about-us",
    element: <AboutUs />,
  },
  {
    path: "financial-news",
    element: <Blog />,
  },
  {
    path: "services",
    element: <Services />,
  },
  {
    path: "*",
    element: <Navigate to={"/dashboard"} />,
  },
]);

function App() {
  const { user } = useSelector((state) => state.user);

  return (
    <>
      <Elements stripe={stripePromise}>
        <HelmetProvider>
          <RouterProvider router={user?.email ? protectedRouter : router} />
          <ToastContainer
            position="top-right"
            autoClose={3000}
            closeOnClick
            ltr={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            hideProgressBar={true}
          />
        </HelmetProvider>
      </Elements>
    </>
  );
}

export default App;
