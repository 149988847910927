import { createAsyncThunk } from "@reduxjs/toolkit";
import { VERIFY_BANK } from "../../../config/apis";
import { Client } from "../../../config/client";
import { toast } from "react-toastify";

export const verifyBank = createAsyncThunk(
  "bank/verify",
  async ({ payload, cb, setgettingAccountName, deleteLastDegit }) => {
    const { accountNumber, bankCode } = payload;
    try {
      const { data } = await Client({
        method: "GET",
        path: `${VERIFY_BANK}?accountNumber=${accountNumber}&bankCode=${bankCode}`,
      });

      setgettingAccountName(false);
      if (data?.account_name) {
        cb(data?.account_name);
      } else {
        deleteLastDegit();
      }
    } catch (error) {
      setgettingAccountName(false);
      deleteLastDegit();
      // toast.error(error?.response?.data?.message || "Something went wrong");
      throw error;
    }
  }
);
