import React, { useEffect, useMemo, useState } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import SideBar from "../Components/Bars/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../Components/Inputs/Button";
import TextInput from "../Components/Inputs/TextInput";
import { formHandler } from "../utils/formhandler";
import { useFormik } from "formik";
import CurrencyComponent from "../Components/Inputs/CurrencyComponent";
import { TbArrowsExchange } from "react-icons/tb";
import RadioButton from "../Components/Inputs/RadioButton";
import SelectField from "../Components/Inputs/SelectField";
import DatePicker from "../Components/Inputs/DatePicker";
import { createRecurring } from "../store/actions/recurring/createRecurring";
import { recurringSchema } from "../utils/validationSchemas";
import { getTransferRates } from "../store/actions/transactions/getTransferRates";

const RecurringPayments = () => {
  const { rates } = useSelector((state) => state.transactions);
  const { data: bankData } = useSelector((state) => state?.banks);
  const { state } = useLocation();
  const oldState = state?.oldState;

  const [currencyName1, setCurrencyName1] = useState(
    oldState?.currency || "GBP"
  );
  const [currencyName2, setCurrencyName2] = useState(
    oldState?.recipientCurrency || "NGN"
  );
  const [currency1, setCurrency1] = useState(1);
  const [currency2, setCurrency2] = useState(
    rates?.find((rate) => rate?.currency === oldState?.currency || "GBP")
      ?.quotes[
      `${oldState?.currency || "GBP"}${oldState?.recipientCurrency || "NGN"}`
    ]
  );

  const rate = useMemo(
    () =>
      rates?.find((rate) => rate?.currency === currencyName1)?.quotes[
        `${currencyName1}${currencyName2}`
      ],
    [currencyName1, currencyName2, rates]
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: {
      paymentName: "",
      recipientAccountNumber: "",
      recipientBankName: null,
      swiftCode: "",
      amount: "",
      currency: "GBP",
      recipientCurrency: "NGN",
      frequency: null,
      startDate: null,
      endDate: null,
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: recurringSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const data = {
        ...values,
        recipientBankName: values?.recipientBankName?.value,
      };

      dispatch(
        createRecurring({
          cb: () => {
            resetForm();
            setSubmitting(false);
            navigate("/payments");
            // setShowSuccess(true);
          },
          payload: data,
          failed: () => {
            setSubmitting(false);
          },
        })
      );
    },
  });

  useEffect(() => {
    if (state) {
      const { bankName, accountNumber, swiftCode } = state;

      form.setValues({
        ...form.values,
        recipientAccountNumber: accountNumber,
        recipientBankName: { id: bankName, label: bankName },
        swiftCode,
      });
    }
  }, [state]);

  useEffect(() => {
    dispatch(getTransferRates());
  }, []);

  // useEffect(() => {
  //   if (oldState) {
  //     // form.setValues(state);
  //   }
  // }, [oldState]);

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Recurring Payments"}
          description={""}
          name={"Imperial Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <SideBar />
          <div className="flex-1 mb-14 max-w-[100vw] overflow-x-auto pt-6 pb-6 px-5 md:px-9 bg-[#F1F1F1]">
            <div className="flex items-center justify-between">
              <h4 className="font-[600] text-lg mb-1">Recurring Payments</h4>
            </div>

            <div className="mt-5  w-full">
              <div className="flex w-full  max-w-[95%] flex-col  mx:auto sm:max-w-[804px] mb-6 py-4 flex-wrap gap-3">
                <div className="flex w-full md:w-[799px] flex-col mb-4 lg:flex-row  justify-between">
                  <TextInput
                    title={"Payment Name"}
                    {...formHandler("paymentName", form)}
                    type={"text"}
                    // placeholder={"e.g Williams Jones "}
                    mainClasses={"!w-full sm:max-w-[323px]"}
                  />

                  <TextInput
                    title={"Recipient Account Number"}
                    {...formHandler("recipientAccountNumber", form)}
                    type={"number"}
                    // placeholder={"e.g Jones "}
                    mainClasses={"!w-full sm:max-w-[323px]"}
                  />
                </div>
                <div className="flex w-full md:w-[799px]  flex-col mb-4 lg:flex-row  justify-between">
                  <div className="w-full  mx-auto">
                    <div className="w-full md:w-[323px]">
                      <SelectField
                        title="Recipient Bank"
                        id="recipientBankName"
                        // clearFields={[
                        //   // "account_name",
                        //   "account_numberUSD",
                        //   "sort_code",
                        //   "swift_code",
                        //   "routing_number",
                        //   "IBAN",
                        //   "beneficiary_bank_name",
                        //   "beneficiary_name",
                        //   "SWIFT_BIC",
                        //   "beneficiary_bank_address",
                        //   "beneficiary_address",
                        //   "beneficiary_country",
                        // ]}
                        form={form}
                        options={bankData?.map((i) => ({
                          ...i,
                          label: i.name,
                          value: i.name,
                        }))}
                        sx={{ width: "100%", height: "50px" }}
                      />
                    </div>
                    <h4
                      onClick={() =>
                        navigate("/beneficiaries?from=recurring-payments")
                      }
                      className="cursor-pointer font-[500] text-[13px] mt-1 md:text-[14px] text-[#395AB5]  "
                    >
                      Select from beneficiary
                    </h4>
                  </div>

                  <TextInput
                    title={"BIC/Swift Code"}
                    {...formHandler("swiftCode", form)}
                    type={"text"}
                    // placeholder={"e.g Jones "}
                    mainClasses={"!w-full sm:max-w-[323px]"}
                  />
                </div>
              </div>
              <div className="flex max-w-[95%] flex-col min-h-[344px] bg-[#fff] mx:auto sm:max-w-[804px] mb-6 py-7 px-7 rounded-[17px] flex-wrap justify-center">
                <div className="w-full shadow-sm  flex justify-between items-center gap-x-4 flex-col md:flex-row ">
                  <CurrencyComponent
                    titleStyle="!text-[#000000] !max-w-[309px]"
                    mainStyle={"!max-w-[309px]"}
                    defaultCurrency={"gbp"}
                    dropDownStyles={"shadow-sm border-[0.5px] border-t-0"}
                    title={"Amount to send"}
                    items={["GBP", "NGN", "EUR", "USD"]}
                    position="top-[80px]   left-0 z-[1]   "
                    onChangeCurrency={(curr) => {
                      form.setFieldValue("currency", curr);

                      const rate = rates?.find(
                        (rate) => rate?.currency === curr
                      )?.quotes[`${curr}${currencyName2}`];

                      setCurrency2(currency1 * rate);

                      form.setFieldValue("recieverAmount", currency1 * rate);
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCurrency1(value);

                      setCurrency2(value * rate);
                      form.setFieldValue("amount", value);
                      form.setFieldValue("recieverAmount", value * rate);
                    }}
                    inputStyles={
                      "max-w-[309px] border-[#707070] border-[0.5px] "
                    }
                    value={currency1}
                    currency={currencyName1}
                    setSelected={setCurrencyName1}
                  />
                  <TbArrowsExchange
                    color={"#000"}
                    fontWeight={"400"}
                    size={"50px"}
                  />
                  <CurrencyComponent
                    titleStyle="!text-[#000000] !max-w-[309px]"
                    dropDownStyles={"shadow-sm border-[0.5px] border-t-0"}
                    mainStyle={"!max-w-[309px]"}
                    items={["GBP", "NGN", "EUR", "USD"]}
                    inputStyles={
                      "!max-w-[309px] border-[#707070] border-[0.5px] "
                    }
                    position="top-[80px]   left-0 z-[1]   "
                    defaultCurrency={"naira"}
                    setSelected={setCurrencyName2}
                    title={"Receiver gets"}
                    onChangeCurrency={(curr) => {
                      const rate = rates?.find(
                        (rate) => rate?.currency === currencyName1
                      )?.quotes[`${currencyName1}${curr}`];
                      form.setFieldValue("recipientCurrency", curr);
                      setCurrency2(currency1 * rate);
                      form.setFieldValue("recieverAmount", currency1 * rate);
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCurrency2(e.target.value);
                      const rate = rates?.find(
                        (rate) => rate?.currency === currencyName1
                      )?.quotes[`${currencyName1}${currencyName2}`];

                      setCurrency1(value / rate);
                      form.setFieldValue("amount", value / rate);
                      form.setFieldValue("recieverAmount", e.target.value);
                    }}
                    value={currency2}
                    currency={currencyName2}
                  />
                </div>

                <h4 className="font-[600] text-[14px] mt-1 md:text-[16px] text-[#000] mb-3  ">
                  Payment frequency
                </h4>

                <h4 className="font-[400] text-[14px] md:text-[16px] text-[#707070]  ">
                  How often should this payment occur
                </h4>
                <RadioButton
                  showAddMore={false}
                  {...formHandler("frequency", form)}
                  type="text"
                  form={form}
                  id="frequency"
                  label="Reminder Frequency"
                  require
                  autocomplete="off"
                  childrenContent={
                    [
                      {
                        id: 1,
                        name: "Daily",
                      },
                      {
                        id: 2,
                        name: "Weekly",
                      },
                      {
                        id: 3,
                        name: "Monthly",
                      },
                    ]?.reverse() || []
                  }
                  className="w-full"
                  placeholder="Select"
                />
                <div className="flex mt-2 max-w-[320px] justify-between">
                  <div>
                    <h4 className="font-[400] text-[14px] md:text-[16px] text-[#000]">
                      Start Date
                    </h4>
                    <DatePicker
                      form={form}
                      id="startDate"
                      {...formHandler("startDate", form)}
                    />
                  </div>
                  <div>
                    <h4 className="font-[400] text-[14px] md:text-[16px] text-[#000]">
                      End Date
                    </h4>
                    <DatePicker
                      form={form}
                      id="endDate"
                      {...formHandler("endDate", form)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center  max-w-[95%]   sm:max-w-[804px] ">
              <Button
                bg={"#192851"}
                title={"Save"}
                textStyles="font-[600]  text-[18px]"
                mainClasses="h-[56px]  "
                onClick={form.handleSubmit}
                loading={form.isSubmitting}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default RecurringPayments;
