// import { logHelper } from "./logHelper";
import { showToast } from "./showToast";

export const proccessErrorMessage = async (e, category = "Error") => {
  // logHelper(e?.message, 'Error From Proceess Error1');
  // logHelper(e?.response?.data, 'Error From Proceess Error2');
  // logHelper(e?.response?.data?.data, 'Error From Proceess Error3');
  // logHelper(
  //   e?.response?.data?.data?.data?.status,
  //   'Error From Proceess Error4',
  // );
  // logHelper(
  //   e?.response?.data?.data?.data?.next_action,
  //   'next_actionError From Proceess Error',
  // );

  let message;
  switch (
    e?.response?.data?.message ||
    e?.response?.message ||
    e?.message ||
    e
  ) {
    case "Internal Server Error":
      message = "Error, Please try again later.";
      break;
    case "Server Error":
      message = "Error, Please try again later.";
      break;
    case "Unauthenticated.":
      message = "Session expired. Please login again";
      break;
    case "Network Error":
      message = "Check Your Internet Connection";
      break;
    case "Clean could not be found!":
      message = "Your cleaning request has been canceled!";
      break;
    default:
      message =
        e?.response?.data?.message ||
        e?.message ||
        e ||
        "An error has occured!";
      break;
  }

  showToast({
    type: 0,
    title: category,
    message,
  });
};
