import React from "react";
import { BsClock } from "react-icons/bs";

const Post2 = ({ type, handleClick }) => {
  return (
    <div
      onClick={handleClick}
      className={`w-full  cursor-pointer bg-[#FFF] mb-7 gap-6 flex h-[181px]`}
    >
      {type === 2 ? (
        <></>
      ) : (
        <img
          src={require("../../Assets/Images/blog2.png")}
          alt="currency"
          className="w-[266.81px] h-full object-cover"
        />
      )}
      <div className="px-7 pt-2 pb-4 flex-col gap-2 flex justify-center flex-1 mx-auto">
        <h4 className="text-[18px] lg:text-[20px] font-[600] text-[#395AB5]">
          USD/$1 = 0.78
        </h4>
        <h4 className="text-[18px] lg:text-[20px] font-[600] text-[#000]">
          {type === 2
            ? "Bitcoin Hits All-Time High, Surpassing $70,000 Mark..."
            : "Stock Market Surges to New Heights as Tech Giants Lead Rally Amidst Economic Recovery Optimism..."}
        </h4>
        <div
          className={`flex gap-1 items-center ${
            type === 2 ? "" : "justify-end"
          }`}
        >
          <BsClock size={"14px"} color={"#707070"} />
          <h4 className="text-[12px] sm:text-[14px] font-[500] text-[#707070]">
            14 hours ago
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Post2;
